import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { Colors, FontAwesomeSize } from '@partnerhero/new_design_system.utils.enums';

const PanelCard = styled.div`
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    icon: string;
    redirectUrl: string;
    title: string;
    subtitle: string;
}

const RedirectCard: FC<Props> = ({ icon, redirectUrl, title, subtitle }) => {
    return (
        <>
            <PanelCard
                style={{
                    justifyContent: 'space-between',
                    height: '8rem',
                    padding: '0 2rem',
                    boxShadow: 'rgb(100, 100, 111, 0.2) 0px 2px 3px 0px',
                }}
            >
                <IconAndText>
                    <Icon
                        pro
                        icon={icon}
                        color={Colors.black}
                        size={FontAwesomeSize.lg}
                        margin="0 2rem 0 0"
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <p
                                className="bold-large"
                                style={{ margin: 0, color: '#223554', whiteSpace: 'nowrap' }}
                            >
                                {title}
                            </p>
                        </div>
                        <div>
                            <p
                                className="body-small"
                                style={{ margin: 0, color: '#223554', whiteSpace: 'nowrap' }}
                            >
                                {subtitle}
                            </p>
                        </div>
                    </div>
                </IconAndText>
                <Icon
                    pro
                    pointer
                    icon={'faArrowUpRightFromSquare'}
                    color={Colors.darkGrey}
                    size={FontAwesomeSize.lg}
                    margin="0 2rem 0 0"
                    onClick={() => window.open(redirectUrl)}
                />
            </PanelCard>
        </>
    );
};

export default RedirectCard;
