import { FC, useEffect, useState } from 'react';
import { Table } from '@partnerhero/new_design_system.table.table';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import { Colors, Size } from '@partnerhero/new_design_system.utils.enums';

import EditTeamMemberForm from '../../components/EditTeamMemberForm';
import FormLoader from '../../components/Loader/FormLoader';

import { useGetTeamsByWorkspaceId } from '../../services/Team';
import { useChangeTeamMemberFromTeam, useUpdateTeamMember } from '../../services/TeamMember';
import { TeamsByWorkspaceId_getAllTeamsFromWorkspace } from '../../queries/types/TeamsByWorkspaceId';

interface TeamMemberOption {
    'First Name': string;
    'Last Name': string;
    'Zendesk Email': string;
    id: string;
    teamId: string;
}

interface Props {
    teamList: TeamMemberOption[] | undefined;
    workspaceId: string;
    currentTeamId: string;
    teamName: string | undefined;
    onCancel?(): void;
    onOpen?(): void;
    openForm: boolean;
}

const SettingsTeamsList: FC<Props> = ({
    teamList,
    workspaceId,
    currentTeamId,
    teamName,
    onCancel = () => {
        // intended empty
    },
    onOpen = () => {
        // intended empty
    },
    openForm,
}) => {
    const [openLoader, setOpenLoader] = useState(false);
    const [changeTeamMemberFromTeam] = useChangeTeamMemberFromTeam();
    const [updateTeamMemberFromTeam, { loading, error }] = useUpdateTeamMember();
    const [newTeamName, setNewTeamName] = useState<string | undefined>(teamName);
    const [isFormOpen, setFormOpen] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [moved, setMoved] = useState(false);
    const [teamValue, setTeamValue] = useState<{
        teamSlug: string;
        teamName: string;
        teamSlack: string | null;
    }>({
        teamSlug: '',
        teamName: '',
        teamSlack: null,
    });
    const [allTeams, setAllTeams] = useState<TeamsByWorkspaceId_getAllTeamsFromWorkspace[]>([]);
    const [messageNotification, setMessageNotification] = useState<string | undefined>('');
    const [newMemberValues, setNewMemberValues] = useState<{
        name: string | undefined;
        teamName: string | undefined;
    }>({
        name: '',
        teamName: '',
    });
    const [memberToEdit, setMemberToEdit] = useState({
        'First Name': '',
        'Last Name': '',
        ZendeskEmail: '',
        id: '',
        teamId: '',
    });
    const { loading: teamsLoading, refetch } = useGetTeamsByWorkspaceId(
        {
            workspaceId,
        },
        (data) => {
            setAllTeams(data.getAllTeamsFromWorkspace);
        }
    );

    const handleSubmit = (teamId?: string, firstName?: string, lastName?: string) => {
        setNewMemberValues({
            name: `${firstName} ${lastName}`,
            teamName: teamId,
        });
        if (firstName !== memberToEdit['First Name'] || lastName !== memberToEdit['Last Name']) {
            updateTeamMemberFromTeam({
                variables: {
                    editTeamMemberInput: {
                        firstName: firstName,
                        lastName: lastName,
                        teamId: currentTeamId,
                        teamMemberId: memberToEdit.id,
                        workspaceId: workspaceId,
                    },
                },
            });
            setUpdated(true);
            setOpenLoader(true);
        }
        if (teamId !== currentTeamId && teamId !== undefined) {
            changeTeamMemberFromTeam({
                variables: {
                    input: {
                        newTeamId: teamId,
                        oldTeamId: currentTeamId,
                        teamMemberId: memberToEdit.id,
                        workspaceId: workspaceId,
                    },
                },
            });
            setMoved(true);
            setOpenLoader(true);
        }
        setFormOpen(false);
    };

    useEffect(() => {
        if (newTeamName !== teamName) {
            setNewTeamName(teamName);
            refetch({ workspaceId });
        }
    }, [newTeamName, refetch, teamName, workspaceId]);

    useEffect(() => {
        const found = allTeams.filter((team) => team.id === newMemberValues.teamName);
        if (updated && !moved) {
            setMessageNotification(`Saved! ${newMemberValues.name} has been updated successfully.`);
        }

        if (!updated && moved) {
            setMessageNotification(
                `Saved! ${newMemberValues.name} has been moved successfully to ${found[0].name}.`
            );
            setTeamValue({
                teamSlug: found[0].slug,
                teamName: found[0].name,
                teamSlack: found[0].slackChannel,
            });
        }

        if (updated && moved) {
            setMessageNotification(
                `Saved! ${newMemberValues.name} has been updated and moved successfully.`
            );
            setTeamValue({
                teamSlug: found[0].slug,
                teamName: found[0].name,
                teamSlack: found[0].slackChannel,
            });
        }
    }, [
        allTeams,
        moved,
        openLoader,
        newMemberValues.name,
        newMemberValues.teamName,
        teamName,
        updated,
    ]);

    useEffect(() => {
        if (openLoader) {
            setTimeout(() => {
                setOpenLoader(false);
                setOpenNotification(true);
            }, 3000);
        }
        if (openNotification) {
            setTimeout(() => {
                setMessageNotification('');
                setOpenNotification(false);
                setTeamValue({ teamSlug: '', teamName: '', teamSlack: null });
                setMoved(false);
                setUpdated(false);
            }, 3000);
        }
    }, [openLoader, openNotification]);

    useEffect(() => {
        if (!isFormOpen) {
            onOpen();
        }
    }, [isFormOpen, onOpen]);

    return (
        <>
            {openNotification && (
                <Notification
                    notificationAppBar
                    size={Size.small}
                    isOpened={openNotification}
                    text={
                        !!error
                            ? 'There was a problem changing the team members values of your team. Check that the name is correct and try again.'
                            : messageNotification
                    }
                    leftIconProps={{
                        icon: !!error ? 'faExclamationTriangle' : 'faThumbsUp',
                        pro: true,
                    }}
                    addButtons={!!teamValue.teamSlug}
                    notificationBg={!!error ? Colors.error : Colors.success}
                ></Notification>
            )}

            {openLoader && (
                <FormLoader
                    icon="faUserEdit"
                    fullName={newMemberValues.name}
                    text="We are saving edits to"
                />
            )}

            {isFormOpen && (
                <EditTeamMemberForm
                    onSubmit={(d) => {
                        handleSubmit(d.teamId, d.firstName, d.lastName);
                    }}
                    callErrors={error?.graphQLErrors?.map((e) => e.message)}
                    isLoading={loading}
                    teams={allTeams}
                    teamMember={memberToEdit}
                    teamName={newTeamName}
                    onCancel={() => setFormOpen(false)}
                    teamsLoading={teamsLoading}
                />
            )}

            <Table
                data={{
                    columns: [
                        { title: 'First Name' },
                        { title: 'Last Name' },
                        { title: 'Zendesk Email' },
                    ],
                    rows: teamList,
                }}
                rowOptions={{
                    editable: !openForm,
                    deletable: true,
                }}
                onEdit={(teamMemberToEdit: TeamMemberOption) => {
                    setMemberToEdit({
                        ...memberToEdit,
                        'First Name': teamMemberToEdit['First Name'],
                        'Last Name': teamMemberToEdit['Last Name'],
                        ZendeskEmail: teamMemberToEdit['Zendesk Email'],
                        id: teamMemberToEdit.id,
                        teamId: teamMemberToEdit.teamId,
                    });
                    setFormOpen(true);
                    onCancel();
                }}
                onDelete={() => console.log('delete')}
            />
        </>
    );
};

export default SettingsTeamsList;
