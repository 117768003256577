import { useState, FC } from 'react';
import styled from 'styled-components';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import { Colors, Size } from '@partnerhero/new_design_system.utils.enums';

import { loginWithGoogle } from '../../services/Auth';
import AuthPageContainer from '../../components/AuthPage';

const GoogleButton = styled.button`
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    cursor: pointer;
`;

const WelcomeTitle = styled.h4`
    height: 2.5rem;
    color: #223554;
    text-align: center;
`;

const LoginParagraph = styled.p`
    text-align: center;
    color: #223554;
    width: 440px;
    margin-top: 1rem;
`;

const LoginLink = styled.a`
    text-decoration: none;
    margin-top: 2rem;
`;

const Footer = styled.p`
    text-align: center;
    color: #9090a9;
    position: fixed;
    bottom: 0;
`;

const Login: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    return (
        <AuthPageContainer>
            <div style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        width: '40%',
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ alignItems: 'center', marginTop: '30%' }}>
                        <Notification
                            isOpened={isOpen}
                            closeIconProps={{ onClick: () => setIsOpen(false), icon: 'faTimes' }}
                            size={Size.fullWidth}
                            title="You’ve been Invited to Beacon"
                            text="You’ve been invited to Beacon. Use your PartnerHero email to start the sign up process."
                            leftIconProps={{ icon: 'faEnvelopeOpenText', pro: true }}
                            notificationBg={Colors.primary}
                            notificationAppBar
                        />

                        <div
                            style={{
                                width: '40%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <img src="./assets/new-assets/Beacon-Logo.svg" alt="Beacon" />
                        </div>
                        <WelcomeTitle>Welcome to Beacon (beta)</WelcomeTitle>
                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <LoginParagraph className="body-medium">
                                You are about to sign in to a new version of Beacon. Functionality
                                is very <b>limited </b>
                                at the moment.
                            </LoginParagraph>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <LoginParagraph className="body-medium">
                                Don't worry though, over the next year (and with your help) we will
                                be looking to improve <b>every </b> aspect of the application.
                            </LoginParagraph>
                        </div>

                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <GoogleButton type="button" onClick={() => loginWithGoogle()}>
                                Sign in with Google
                            </GoogleButton>
                        </div>
                        <div
                            style={{
                                width: '50%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <LoginLink href="https://beacon.partnerhero.com">
                                Looking for the old Beacon?
                            </LoginLink>
                        </div>

                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <Footer className="body-small">
                                Designed, Developed, and Managed by wonderful folks at Partnerhero.
                                © Copyright 2022
                            </Footer>
                        </div>
                    </div>
                </div>
            </div>
        </AuthPageContainer>
    );
};

export default Login;
