import { useState, useContext, createContext, ReactNode } from 'react';
import { makeVar } from '@apollo/client';
import { User } from './queries/types/User';
import { Workspace } from './queries/types/Workspace';

export const currentWorkspace = makeVar<Workspace | null>(null);

interface AppBar {
    title: string;
    icon: string;
}

interface AppContextTypes {
    idToken: string;
    setIdToken(token: string): void;
    user: User | null;
    setUser(user: User | null): void;
    authToken: string;
    setAuthToken(token: string): void;
    appBar: AppBar;
    setAppBar(appBar: AppBar): void;
    selectedNavLink: string;
    setSelectedNavLink(index: string): void;
    workspaceId: string;
    setWorkspaceId(id: string): void;
    workspaceSlug: string;
    setWorkspaceSlug(slug: string): void;
}

export const AppContext = createContext<AppContextTypes>({
    idToken: '',
    setIdToken: () => {},
    user: null,
    setUser: () => {},
    authToken: '',
    setAuthToken: () => {},
    appBar: {
        title: '',
        icon: '',
    },
    setAppBar: () => {
        // This is intentional
    },
    selectedNavLink: '0',
    setSelectedNavLink: () => {
        // This is intentional
    },
    workspaceId: '',
    setWorkspaceId: () => {
        //This is intentional
    },
    workspaceSlug: '',
    setWorkspaceSlug: () => {
        //This is intentional
    },
});

interface ProviderProps {
    children: ReactNode;
    user?: User | null;
    appBar?: AppBar;
    authToken?: string;
    selectedNavLink?: string;
    idToken?: string;
    worskpaceId?: string;
    workspaceSlug?: string;
}

export const AppContextProvider = ({
    children,
    user: userProp = null,
    idToken: idtk = '',
    authToken: tokenProp = '',
    appBar: appBarProp = {
        title: 'Dashboard',
        icon: 'faTachometerAltAverage',
    },
    selectedNavLink: selectedNavLinkProp = '0',
    worskpaceId: worskpaceIdProp = '',
    workspaceSlug: worskpaceSlugProp = '',
}: ProviderProps) => {
    const [idToken, setIdToken] = useState<string>(idtk);
    const [user, setUser] = useState<User | null>(userProp);
    const [authToken, setAuthToken] = useState<string>(tokenProp);
    const [appBar, setAppBar] = useState(appBarProp);
    const [selectedNavLink, setSelectedNavLink] = useState(selectedNavLinkProp);
    const [workspaceId, setWorkspaceId] = useState<string>(worskpaceIdProp);
    const [workspaceSlug, setWorkspaceSlug] = useState<string>(worskpaceSlugProp);

    return (
        <AppContext.Provider
            value={{
                idToken,
                setIdToken: (newToken) => setIdToken(newToken),
                user,
                setUser: (newUser) => setUser(newUser),
                authToken,
                setAuthToken: (newAuthToken) => setAuthToken(newAuthToken),
                appBar,
                setAppBar: (newAppBar) => setAppBar(newAppBar),
                selectedNavLink,
                setSelectedNavLink: (link) => setSelectedNavLink(link),
                workspaceId,
                setWorkspaceId: (newWorkspaceId) => setWorkspaceId(newWorkspaceId),
                workspaceSlug,
                setWorkspaceSlug: (newWorkspaceSlug) => setWorkspaceSlug(newWorkspaceSlug),
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
