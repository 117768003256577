import { Switch, Route, useRouteMatch } from 'react-router';
import WorkspaceMembersPage from './WorkspaceMembersPage';

// Route: /workspaces/:slug/settings/workspace-members
const Router = () => {
    const { url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${url}`} component={WorkspaceMembersPage} />
        </Switch>
    );
};

export default Router;
