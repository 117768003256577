import dayjs from 'dayjs';
import { FC, useState } from 'react';
import ExportDataModal from '../../../../components/ExportData';
import {
    GetExportData_getExportData_teamsData_teamMembersData_ticketsData,
    GetExportData_getExportData_teamsData_teamMembersData,
} from '../../../../queries/types/GetExportData';

import { useLazyGetExportData } from '../../../../services/GetExportData';

interface Props {
    workspaceId: string;
    teamId: string | null;
    teamName: string | undefined;
    teamMemberId: string | null;
    beginTime: number;
    endTime: number;
    dataAvailable: boolean;
    workspaceSelected: boolean;
    chosenMemberOption: string;
    day: string;
}

const ExportDataContainer: FC<Props> = ({
    workspaceId,
    teamId,
    teamName,
    teamMemberId,
    beginTime,
    endTime,
    dataAvailable,
    workspaceSelected,
    chosenMemberOption,
    day,
}) => {
    const [getExportData, { data, error, loading }] = useLazyGetExportData();
    const [downloadAvailable, setDownloadAvailable] = useState<boolean>(false);

    const getModalString = (filterSelected: string, name: string = '') => {
        return `You can now export all data from the ${name} Team from ${filterSelected} for ${day}. If you would like different data try adjusting the dashboard filters.`;
    };

    const fetchData = () => {
        setDownloadAvailable(true);
        getExportData({
            variables: {
                workspaceId: workspaceId,
                teamId: teamId,
                teamMemberId: teamMemberId,
                beginTime: beginTime,
                endTime: endTime,
            },
        });
    };

    const concatWhiteSpace = (name: string) => {
        return name.replace(/\s+/g, '%20');
    };

    const objectToCSV = () => {
        const headers = [
            ['Workspace', 'Team', 'Assignee', 'Ticket%20Id', 'Status', 'Last%20Update'],
        ];
        const csvROW: Array<string> = [];
        let workspaceName: string;
        let team: string;

        if (!!data) {
            workspaceName = data.getExportData.workspaceName;
            team = data.getExportData.teamsData[0].teamName;
        }

        if (!!data && teamMemberId !== null) {
            const teamMemberName: string =
                data.getExportData.teamsData[0].teamMembersData[0].teamMemberName;

            const ticketsData: Array<GetExportData_getExportData_teamsData_teamMembersData_ticketsData> =
                data.getExportData.teamsData[0].teamMembersData[0].ticketsData;

            ticketsData.forEach(
                (element: GetExportData_getExportData_teamsData_teamMembersData_ticketsData) => {
                    let dateString = new Date(element.lastUpdated * 1000);
                    headers.push([
                        concatWhiteSpace(workspaceName),
                        concatWhiteSpace(team),
                        concatWhiteSpace(teamMemberName),
                        element.ticketId,
                        element.status,
                        dayjs(dateString).format('DD/MM/YYYY %20 HH:mm'),
                    ]);
                }
            );
        }

        if (!!data && !!workspaceId && teamMemberId === null) {
            const ticketsData = data.getExportData.teamsData[0].teamMembersData;

            ticketsData.forEach(
                (valueArray: GetExportData_getExportData_teamsData_teamMembersData) => {
                    const teamMemberName = valueArray.teamMemberName;
                    const eachTicketData = valueArray.ticketsData;

                    eachTicketData.forEach(
                        (
                            element: GetExportData_getExportData_teamsData_teamMembersData_ticketsData
                        ) => {
                            let date = new Date(element.lastUpdated * 1000);
                            headers.push([
                                concatWhiteSpace(workspaceName),
                                concatWhiteSpace(team),
                                concatWhiteSpace(teamMemberName),
                                element.ticketId,
                                element.status,
                                dayjs(date).format('DD/MM/YYYY %20 HH:mm'),
                            ]);
                        }
                    );
                }
            );
        }

        headers.forEach((e) => {
            csvROW.push(e.join(','));
        });

        const csvString: string = csvROW.join('%0A');

        const fileToCSV = document.createElement('a');
        fileToCSV.href = 'data:attachment/csv,' + csvString;
        fileToCSV.target = '_Blank';
        fileToCSV.download = 'data.csv';
        document.body.appendChild(fileToCSV);
        fileToCSV.click();
        setDownloadAvailable(false);
    };

    return (
        <ExportDataModal
            objectToCSV={objectToCSV}
            downloadAvailable={downloadAvailable}
            dataAvailable={dataAvailable}
            loading={loading}
            error={!!error}
            workspaceSelected={workspaceSelected}
            fetchData={fetchData}
            modalText={getModalString(chosenMemberOption, teamName)}
            onCancel={() => setDownloadAvailable(false)}
        />
    );
};

export default ExportDataContainer;
