import { gql } from '@apollo/client';

export const Workspace = gql`
    fragment Workspace on Workspace {
        id
        slug
        settings {
            name
            createdAt
        }
    }
`;

export const CREATE_WORKSPACE = gql`
    mutation CreateWorkspace($input: CreateWorkspaceInput!) {
        createWorkspace(input: $input) {
            ...Workspace
        }
    }
    ${Workspace}
`;
