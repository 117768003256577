import { FC, useEffect, useState, useMemo } from 'react';
import { Modal } from '@partnerhero/new_design_system.modal.modal';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Colors, Size } from '@partnerhero/new_design_system.utils.enums';

const defaultValues = {
    modalColor: Colors.primary,
    modalIcon: 'faFileExport',
    modalClassName: '',
    modalTitle: 'Export Current Filtered Data',
    modalButtonText: 'Export Data as .CSV',
    isConfirmBtnDisabled: false,
    btnCancelText: 'Cancel or Adjust Filters',
};

interface ModalValues {
    modalText: string;
    modalColor: Colors;
    modalIcon: string;
    modalClassName: string;
    modalTitle: string;
    modalButtonText: string;
    isConfirmBtnDisabled: boolean;
    btnCancelText: string;
}

interface Props {
    objectToCSV?(): void;
    downloadAvailable: boolean;
    dataAvailable: boolean;
    loading: boolean;
    error: boolean;
    workspaceSelected: boolean;
    fetchData(): void;
    modalText: string;
    onCancel?(): void;
}

const ExportDataModal: FC<Props> = ({
    objectToCSV = () => {
        // this is intended
    },
    downloadAvailable,
    dataAvailable,
    loading,
    error,
    workspaceSelected,
    fetchData,
    modalText,
    onCancel = () => {
        // intended empty
    },
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalValues, setModalValues] = useState<ModalValues>({
        ...defaultValues,
        modalText: modalText,
    });
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        onCancel();
        setFirstFetch(true);
        setModalValues({ ...defaultValues, modalText });
        setIsOpen(false);
    };
    const [firstFech, setFirstFetch] = useState<boolean>(true);

    useEffect(() => {
        if (!!modalText && firstFech) {
            setModalValues({ ...defaultValues, modalText: modalText });
        }
    }, [firstFech, modalText]);

    const propsForModal = useMemo(
        () => [
            {
                modalText:
                    'We are just processing the data that you have requested, it shouldn’t take long, it will then be available for download.',
                modalColor: Colors.primary,
                modalIcon: 'faCircleNotch',
                modalClassName: 'fa-spin',
                modalTitle: 'Hold Tight',
                modalButtonText: 'Export Data as .CSV',
                isConfirmBtnDisabled: true,
                btnCancelText: 'Cancel or Adjust Filters',
            },
            {
                modalText: `You can now download the data you requested.`,
                modalColor: Colors.success,
                modalIcon: 'faDownload',
                modalClassName: '',
                modalTitle: 'Download Now Available',
                modalButtonText: 'Download .CSV',
                isConfirmBtnDisabled: false,
                btnCancelText: 'Cancel',
            },
            {
                modalText: 'There has been a problem creating your .CSV',
                modalColor: Colors.error,
                modalIcon: 'faTriangleExclamation',
                modalClassName: '',
                modalTitle: 'Ah…this is embarrassing',
                modalButtonText: 'Retry .CSV Download',
                isConfirmBtnDisabled: false,
                btnCancelText: 'Cancel',
            },
        ],
        []
    );

    useEffect(() => {
        if (error) {
            setModalValues(propsForModal[2]);
        }

        if (loading) {
            setModalValues(propsForModal[0]);
        }

        if (downloadAvailable && dataAvailable && !loading) {
            setModalValues(propsForModal[1]);
        }
    }, [dataAvailable, downloadAvailable, error, loading, propsForModal]);

    const onClick = () => {
        objectToCSV();
        setModalValues({ ...defaultValues, modalText });
        handleClose();
    };

    return (
        <>
            <Button
                data-testid="ExportData_button"
                type="button"
                disabled={!dataAvailable || !workspaceSelected}
                onClick={handleOpen}
                buttonColor={Colors.primary}
                leftIcon
                iconText="Export Data"
                size={Size.small}
                margin={'0 4rem 0 0'}
                iconProps={{ icon: 'faFileExport', pro: true, color: Colors.white, margin: '0' }}
            />
            <Modal
                data-testid="ExportData_modal"
                isOpen={isOpen}
                handleClose={handleClose}
                title={modalValues.modalTitle}
                bodyText={modalValues.modalText}
                confirmModal
                btnFunc={() => {
                    !downloadAvailable ? fetchData() : onClick();
                }}
                isConfirmBtnDisabled={modalValues.isConfirmBtnDisabled}
                btnCancelText={modalValues.btnCancelText}
                btnText={modalValues.modalButtonText}
                btnColor={modalValues.modalColor}
                confirmModalIcon={{
                    className: modalValues.modalClassName,
                    icon: modalValues.modalIcon,
                    pro: true,
                    color: modalValues.modalColor,
                }}
            />
        </>
    );
};

export default ExportDataModal;
