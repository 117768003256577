import styled from 'styled-components';
import { FC } from 'react';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';

const FormLoaderContainer = styled.div`
    height: 27rem;
    widht: 100%;
    background-color: white;
    margin-bottom: 3rem;
`;

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface Props {
    icon: string;
    fullName: string | undefined;
    text: string | undefined;
}

const FormLoader: FC<Props> = ({ icon, fullName, text }) => {
    return (
        <FormLoaderContainer>
            <CenterContainer>
                <Icon
                    pro
                    icon={icon}
                    color={Colors.primary}
                    size={FontAwesomeSize.threeX}
                    margin="3rem 0 0 0"
                />
                <div>
                    <h3>Hold on...</h3>
                </div>
                <div>
                    <p style={{ fontSize: '1.4rem' }}>{`${text} ${fullName}`}</p>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Icon
                        pro
                        className="fa-spin"
                        icon="faCircleNotch"
                        color={Colors.primary}
                        size={FontAwesomeSize.twoX}
                        margin="0"
                    />
                </div>
            </CenterContainer>
        </FormLoaderContainer>
    );
};

export default FormLoader;
