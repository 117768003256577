import { gql } from '@apollo/client';

export enum TicketStatus {
    open = 'open',
    solved = 'solved',
    pending = 'pending',
    onHold = 'onHold',
}

export const ProductivityNodeTotals = gql`
    fragment ProductivityNodeTotals on ProductivityNodeTotals {
        open
        pending
        onHold
        solved
    }
`;

export const HourlyNode = gql`
    fragment HourlyNode on HourlyNode {
        timestamp
        ticketAmount
    }
`;

export const HourlyCrunch = gql`
    fragment HourlyCrunch on HourlyCrunch {
        data {
            timestamp
            ticketAmount
        }
    }
`;

export const PRODUCTIVITY_CRUNCH = gql`
    query ProductivityCrunch(
        $workspaceId: String!
        $teamId: String
        $teamMemberId: String
        $beginTime: Float!
        $endTime: Float!
    ) {
        productivityCrunch(
            workspaceId: $workspaceId
            teamId: $teamId
            teamMemberId: $teamMemberId
            beginTime: $beginTime
            endTime: $endTime
        ) {
            ...ProductivityNodeTotals
        }
    }
    ${ProductivityNodeTotals}
`;

export const GET_HOURLY_CRUNCH = gql`
    query GetHourlyCrunch(
        $workspaceId: String!
        $teamId: String
        $teamMemberId: String
        $beginTime: Float!
        $endTime: Float!
        $status: TicketStatus!
    ) {
        getHourlyCrunch(
            workspaceId: $workspaceId
            teamId: $teamId
            teamMemberId: $teamMemberId
            beginTime: $beginTime
            endTime: $endTime
            status: $status
        ) {
            ...HourlyCrunch
        }
    }
    ${HourlyCrunch}
`;
