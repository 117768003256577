import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { useAppContext } from '../AppContext';
import { CreateTeam, CreateTeamVariables } from '../queries/types/CreateTeam';
import {
    CREATE_TEAM,
    TEAMS_BY_WORKSPACEID,
    RENAME_TEAM,
    RENAME_SLACK_CHANNEL,
} from '../queries/Teams';
import { RenameTeam, RenameTeamVariables } from '../queries/types/RenameTeam';
import { RenameSlackInput, RenameSlackInputVariables } from '../queries/types/RenameSlackInput';
import {
    TeamsByWorkspaceId,
    TeamsByWorkspaceIdVariables,
} from '../queries/types/TeamsByWorkspaceId';

export const useCreateTeam = (
    onCompleted?: (data: CreateTeam) => void,
    onError?: (error: ApolloError) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useMutation<CreateTeam, CreateTeamVariables>(CREATE_TEAM, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        onCompleted,
        onError,
    });
};

export const useRenameTeam = () => {
    const { idToken, authToken } = useAppContext();
    return useMutation<RenameTeam, RenameTeamVariables>(RENAME_TEAM, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        onError: () => {
            // This is intentional
        },
    });
};

export const useUpdateChannel = () => {
    const { idToken, authToken } = useAppContext();
    return useMutation<RenameSlackInput, RenameSlackInputVariables>(RENAME_SLACK_CHANNEL, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        onError: () => {
            // This is intentional
        },
    });
};

export const useGetTeamsByWorkspaceId = (
    variables: TeamsByWorkspaceIdVariables,
    onCompleted?: (data: TeamsByWorkspaceId) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useQuery<TeamsByWorkspaceId, TeamsByWorkspaceIdVariables>(TEAMS_BY_WORKSPACEID, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        variables,
        onCompleted,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only', // Used for first execution
    });
};
