import styled from 'styled-components';
import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Input } from '@partnerhero/new_design_system.form_elements.input';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors, Size } from '@partnerhero/new_design_system.utils.enums';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;

const Column = styled.div`
    flex-basis: 0;
    flex-grow: 1;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    onSubmit(data: IForm): void;
    callErrors: string[] | undefined;
    isLoading: boolean;
    onCancel?(): void;
}

interface IForm {
    teamName: string;
}

const NewTeamForm: FC<Props> = ({
    onSubmit,
    callErrors,
    isLoading,
    onCancel = () => {
        // intended empty
    },
}) => {
    const {
        handleSubmit,
        formState: { errors, isDirty },
        setError,
        control,
        reset,
        clearErrors,
    } = useForm<IForm>({
        mode: 'onSubmit',
    });

    useEffect(() => {
        callErrors?.forEach((message) =>
            setError('teamName', {
                type: 'validate',
                message: message,
            })
        );
    }, [callErrors, setError, isLoading]);

    return (
        <StyledContainer data-testid="NewTeamForm_StyledContainer">
            <Column
                style={{ display: 'flex', alignItems: 'center' }}
                data-testid="NewTeamForm_IconContainer"
            >
                <IconAndText data-testid="iconAndText-container1">
                    <Column data-testid="icon-column1">
                        <Icon
                            pro
                            icon="faUsersMedical"
                            color={Colors.black}
                            size={FontAwesomeSize.twoX}
                            margin="0 0 0 2rem"
                        />
                    </Column>
                    <Column style={{ flexGrow: 2, margin: '0' }} data-testid="title-column1">
                        <h5 style={{ color: 'rgb(34, 53, 84)' }}>Create a New Team</h5>
                        <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                            Simply choose a name for your new team.
                        </p>
                    </Column>
                </IconAndText>
            </Column>
            <Column>
                <form
                    data-testid="form-container"
                    accept-charset="utf-8"
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                >
                    <Grid container direction="column" wrap="wrap" data-testid="form-mainColumn">
                        <Controller
                            render={({ field: { onChange } }) => (
                                <Input
                                    data-testid="input"
                                    onChange={onChange}
                                    placeholder="Boca Juniors"
                                    error={!!errors.teamName}
                                    errorText={errors?.teamName?.message || ''}
                                    label="Team Name"
                                    subLabel="*required"
                                />
                            )}
                            name="teamName"
                            rules={{
                                required: 'Team name is required',
                                pattern: {
                                    value: /^(?=.*[^\W_])[\w' ]*$/,
                                    message:
                                        'Ooops...No special characters. Try using letters and/or numbers instead.',
                                },
                            }}
                            control={control}
                        />

                        <div
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            data-testid="buttons-column"
                        >
                            <div>
                                <Button
                                    fullWidth
                                    margin="0px"
                                    buttonColor={Colors.grey}
                                    onClick={() => {
                                        reset(
                                            { teamName: '' },
                                            {
                                                keepErrors: false,
                                                keepDirty: false,
                                                keepIsSubmitted: false,
                                                keepIsValid: false,
                                                keepTouched: false,
                                            }
                                        );
                                        clearErrors();
                                        onCancel();
                                    }}
                                    buttonText="Cancel"
                                    type="button"
                                    size={Size.small}
                                />
                            </div>
                            <div>
                                <Button
                                    fullWidth
                                    margin={'0px'}
                                    disabled={!!errors.teamName || isLoading || !isDirty}
                                    buttonColor={Colors.success}
                                    onClick={() => {
                                        // intended empty
                                    }}
                                    type="submit"
                                    buttonText={isLoading ? 'Creating team...' : 'Create Team'}
                                    size={Size.small}
                                />
                            </div>
                        </div>
                    </Grid>
                </form>
            </Column>
        </StyledContainer>
    );
};

export default NewTeamForm;
