import styled from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Input } from '@partnerhero/new_design_system.form_elements.input';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Dropdown } from '@partnerhero/new_design_system.form_elements.dropdown';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import { FontAwesomeSize, Colors, Size } from '@partnerhero/new_design_system.utils.enums';
import FormLoader from '../../components/Loader/FormLoader';

const DropdownFilters = [
    { value: 'allMembers', text: 'All Members' },
    { value: 'active', text: 'Active' },
    { value: 'inactive', text: 'Inactive' },
];

const StyledContainer = styled.div`
    display: flex;
    height: 100%;
    margin-bottom: 20px;
`;

const BlockContainer = styled.div`
    flex-grow: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    @media (min-width: 768px) {
        flex-direction: row;
        z-index: 1;
        width: 100%;
    }
`;

const CenterBlockColumn = styled.div`
    @media (min-width: 768px) {
        padding: 2rem;
        width: 100%;
    }
`;

interface Props {
    onSubmit(data: IForm): void;
    onCancel?(success: boolean): void;
    callErrors: string[] | undefined;
    isLoading: boolean;
    teamName: string | undefined;
    disabledAddButton?: boolean;
    enabledAddButton?: boolean;
    teamMembersuccess?: boolean;
}

interface IForm {
    teamName: string;
    firstName: string;
    lastName: string;
    zendeskEmail: string;
}

const NewTeamMemberForm: FC<Props> = ({
    onSubmit,
    onCancel = (success: boolean) => {
        // intended empty
    },
    callErrors,
    isLoading,
    teamName,
    teamMembersuccess,
}) => {
    const [openNotification, setOpenNotification] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [openForm, setOpenForm] = useState(true);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [teamMembersEmail, setTeamMemberEmail] = useState<string>('');
    const [inputValue, setInputValue] = useState(true);
    const [fullName, setFullName] = useState<string>('');
    const {
        handleSubmit,
        formState: { errors, isDirty },
        control,
        reset,
        clearErrors,
        setError,
    } = useForm<IForm>({
        mode: 'onSubmit',
    });

    const inputValidation = (inputToValidate: string) => {
        const regex = /^[a-zA-Z\u00C0-\u017F]+(?:\s[a-zA-Z\u00C0-\u017F]+)*$/;
        let checkIfContains = regex.test(inputToValidate);
        setInputValue(!checkIfContains);
    };

    useEffect(() => {
        callErrors?.forEach((message) =>
            setError('zendeskEmail', {
                type: 'validate',
                message: message,
            })
        );
    }, [callErrors, setError, isLoading]);

    useEffect(() => {
        if (isLoading && teamMembersuccess) {
            setOpenForm(false);
            setOpenLoader(true);
            setTimeout(() => {
                setOpenLoader(false);
                setOpenNotification(true);
            }, 3000);
        }

        if (isLoading && !teamMembersuccess) {
            setOpenForm(false);
            setOpenLoader(true);
            setTimeout(() => {
                setOpenLoader(false);
                setOpenNotification(true);
                setOpenForm(true);
            }, 3000);
        }
    }, [isLoading, teamMembersuccess]);

    return (
        <div data-testid="NewTeamMemberForm_container">
            <Notification
                notificationAppBar
                size={Size.small}
                isOpened={openNotification}
                closeIconProps={{ onClick: () => setOpenNotification(false), icon: 'faTimes' }}
                text={
                    <p style={{ paddingLeft: '2rem' }}>
                        There was a problem adding {fullName}. Check that the details are correct
                        and try again
                    </p>
                }
                leftIconProps={{
                    icon: 'faExclamationTriangle',
                    pro: true,
                }}
                notificationBg={Colors.error}
            />

            {openLoader && (
                <FormLoader icon="faUserPlus" fullName={fullName} text="We are adding" />
            )}

            {openForm && (
                <StyledContainer data-testid="NewTeamMemberForm_styledContainer">
                    <BlockContainer data-testid="NewTeamMemberForm_blockContainer">
                        <Icon
                            pro
                            icon={'faUsersMedical'}
                            color={Colors.black}
                            size={FontAwesomeSize.twoX}
                            margin="0 0 0 20px"
                        />
                        <CenterBlockColumn data-testid="NewTeamMemberForm_CenterBlockColumn_description">
                            <h5 style={{ color: 'rgb(34, 53, 84)' }}>Add New Member</h5>
                            <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                                You can track any member that is currently using your Zendesk
                                account.
                            </p>
                            <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                                Simply provide their information here, and we will bring in their
                                data!
                            </p>
                        </CenterBlockColumn>
                        <CenterBlockColumn data-testid="NewTeamMemberForm_CenterBlockColumn_form">
                            <form
                                data-testid="NewTeamMemberForm_form"
                                accept-charset="utf-8"
                                onSubmit={handleSubmit((data) => {
                                    setFullName(`${data.firstName} ${data.lastName}`);
                                    onSubmit(data);
                                })}
                            >
                                <Grid container direction="column" wrap="wrap">
                                    <Dropdown
                                        options={DropdownFilters}
                                        label="Team"
                                        subLabel="*required"
                                        disabled
                                        onClick={() => console.log('Selected')}
                                        placeholder={teamName}
                                    />

                                    <Controller
                                        render={({ field: { onChange } }) => (
                                            <Input
                                                onChange={(e) => {
                                                    const targetValue = e.target.value;
                                                    setFirstName(targetValue);
                                                    inputValidation(targetValue);
                                                    onChange(targetValue);
                                                }}
                                                placeholder="Francis"
                                                value={firstName}
                                                error={!!errors.firstName}
                                                errorText={errors?.firstName?.message}
                                                label="First Name"
                                                subLabel="*required"
                                            />
                                        )}
                                        name="firstName"
                                        defaultValue={firstName}
                                        rules={{
                                            required: 'First name is required',
                                            pattern: {
                                                value: /^(?=.*[^\W_])[\w' ]*$/,
                                                message:
                                                    'Ooops...No special characters. Try using letters and/or numbers instead.',
                                            },
                                        }}
                                        control={control}
                                    />

                                    <Controller
                                        render={({ field: { onChange } }) => (
                                            <Input
                                                onChange={(e) => {
                                                    const targetValue = e.target.value;
                                                    setLastName(targetValue);
                                                    inputValidation(targetValue);
                                                    onChange(targetValue);
                                                }}
                                                placeholder="Drake"
                                                value={lastName}
                                                error={!!errors.lastName}
                                                errorText={errors?.lastName?.message || ''}
                                                label="Last Name"
                                                subLabel="*required"
                                            />
                                        )}
                                        name="lastName"
                                        defaultValue={lastName}
                                        rules={{
                                            required: 'Last name is required',
                                            pattern: {
                                                value: /^(?=.*[^\W_])[\w' ]*$/,
                                                message:
                                                    'Ooops...No special characters. Try using letters and/or numbers instead.',
                                            },
                                        }}
                                        control={control}
                                    />

                                    <Controller
                                        render={({ field: { onChange } }) => (
                                            <Input
                                                type="email"
                                                value={teamMembersEmail}
                                                onChange={(e) => {
                                                    const targetValue = e.target.value;
                                                    setInputValue(false);
                                                    setTeamMemberEmail(targetValue);
                                                    onChange(targetValue);
                                                }}
                                                placeholder="francis.drake@partnerhero.com"
                                                error={!!errors.zendeskEmail}
                                                errorText={errors?.zendeskEmail?.message || ''}
                                                label="Zendesk Email"
                                                subLabel="*required"
                                            />
                                        )}
                                        name="zendeskEmail"
                                        defaultValue={teamMembersEmail}
                                        rules={{
                                            required: 'Zendesk email is required',
                                            pattern: {
                                                value: /^\S+@\S+\.\S+$/,
                                                message: 'Please add a valid email.',
                                            },
                                        }}
                                        control={control}
                                    />

                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div>
                                            <Button
                                                fullWidth
                                                margin={'0px'}
                                                buttonColor={Colors.grey}
                                                onClick={() => {
                                                    reset(
                                                        { zendeskEmail: '' },
                                                        {
                                                            keepErrors: false,
                                                            keepDirty: false,
                                                            keepIsSubmitted: false,
                                                            keepIsValid: false,
                                                            keepTouched: false,
                                                        }
                                                    );
                                                    clearErrors();
                                                    onCancel(false);
                                                }}
                                                buttonText="Cancel"
                                                type="button"
                                                size={Size.small}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                fullWidth
                                                margin={'0px'}
                                                disabled={
                                                    !!errors.firstName ||
                                                    !!errors.lastName ||
                                                    !!errors.zendeskEmail ||
                                                    isLoading ||
                                                    !isDirty ||
                                                    inputValue
                                                }
                                                buttonColor={Colors.success}
                                                onClick={() => {
                                                    // intended empty
                                                }}
                                                type="submit"
                                                buttonText={
                                                    isLoading
                                                        ? 'Creating team member...'
                                                        : 'Add Member'
                                                }
                                                size={Size.small}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </form>
                        </CenterBlockColumn>
                    </BlockContainer>
                </StyledContainer>
            )}
        </div>
    );
};

export default NewTeamMemberForm;
