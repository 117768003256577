import { useEffect, useState, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

import Loader from '../components/Loader/SimpleLoader';

interface HistoryState {
    from: string;
    fromSearch: string;
}

interface Props {
    children: ReactNode;
}

const FirebaseAuth = ({ children }: Props) => {
    const { setIdToken } = useAppContext();
    const [firebaseUser, loading] = useFirebaseAuth();
    const [redirecting, setRedirecting] = useState(false);
    const location = useLocation<HistoryState>();
    const history = useHistory<HistoryState>();

    useEffect(() => {
        if (!firebaseUser && location.pathname !== '/login') {
            history.push({
                pathname: '/login',
                state: { from: location.pathname, fromSearch: location.search },
            });
        }
    }, [firebaseUser, history, location]);

    useEffect(() => {
        if (firebaseUser && location.pathname === '/login') {
            setRedirecting(true);
            firebaseUser
                .getIdToken()
                .then((token) => {
                    setIdToken(token);
                    setRedirecting(false);
                })
                .catch(() => {
                    setRedirecting(false);
                });
        }
    }, [firebaseUser, location, setIdToken]);

    return loading || redirecting ? <Loader /> : <>{children}</>;
};
export default FirebaseAuth;
