import { FC, useEffect, useState } from 'react';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Colors } from '@partnerhero/new_design_system.utils.enums';
import RequestFormCard from '../../../../components/RequestFormCard';
import MetricCards from '../../../../components/MetricCards';
import Linegraph from '../../../../components/Linegraph';
import { TeamsByWorkspaceId_getAllTeamsFromWorkspace } from '../../../../queries/types/TeamsByWorkspaceId';

interface Card {
    id: string;
    title: string;
    amount: number;
}

interface MembersFilter {
    value: string;
    text: string;
}

interface ChosenOption {
    value: string;
    text: string;
}

interface Props {
    fetchError: boolean;
    membersData: boolean;
    allTeams: TeamsByWorkspaceId_getAllTeamsFromWorkspace[];
    chosenOption: ChosenOption;
    membersFilter: Array<MembersFilter>;
    productivityCards: Array<Card> | null;
    loading: boolean;
    hourlyCrunchError: any;
    hourlyLinegraphData: any;
    linegraphTitle: string;
    linegraphNameTitle: string | null;
}

const MetricsStatsContainer: FC<Props> = (props) => {
    const {
        fetchError,
        membersData,
        allTeams,
        chosenOption,
        membersFilter,
        productivityCards,
        loading,
        hourlyCrunchError,
        hourlyLinegraphData,
        linegraphNameTitle,
        linegraphTitle,
    } = props;
    const [solvedAmount, setSolvedAmount] = useState<number>(0);

    useEffect(() => {
        if (productivityCards) {
            setSolvedAmount(productivityCards[0].amount);
        }
    }, [productivityCards]);

    return (
        <>
            {fetchError ||
            membersData ||
            !allTeams.length ||
            (chosenOption.value === 'team' && membersFilter.length <= 1) ? (
                <RequestFormCard
                    isErrorDialog
                    title="Hmmm…there's no data for your selected filters"
                    description="Adjust your filters or head to settings to check your configurations."
                    icon="faTelescope"
                    iconColor={Colors.primary}
                    buttonColor={Colors.success}
                    buttonText="Check Configurations"
                    href="https://forms.monday.com/forms/24193a9d3ef6e112dc5ba867eec5af04?r=use1"
                />
            ) : (
                <>
                    <MetricCards cards={productivityCards} loading={loading} />
                    {!hourlyCrunchError && hourlyLinegraphData && solvedAmount > 0 && (
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Linegraph
                                    hourlyLinegraphData={hourlyLinegraphData}
                                    title={linegraphTitle}
                                    who={linegraphNameTitle}
                                    dataLabel="Solved Tickets"
                                />
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default MetricsStatsContainer;
