import { Switch, Route, useRouteMatch } from 'react-router';
import PolicyLinksPage from './PolicyLinks';

// Route: /workspaces/:slug/settings/policies
const Router = () => {
    const { url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${url}`} component={PolicyLinksPage} />
        </Switch>
    );
};

export default Router;
