import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import EditTeamForm from '../../../../../components/EditTeamForm';
import TeamMembersList from '../../../../../components/TeamMembersList/TeamMembersList';

import { useRenameTeam, useUpdateChannel } from '../../../../../services/Team';
import { useAppContext } from '../../../../../AppContext';

const TeamSettings: FC = () => {
    const { workspaceId } = useAppContext();
    const [renameTeam, { loading, error, data: newTeamName }] = useRenameTeam();
    const [updateSlackChannel, { loading: slackInputLoading, data: newSlackName }] =
        useUpdateChannel();

    const [locationChange, setLocationChange] = useState<boolean>(false);
    const isMounted = useRef(false);

    const teamDataLocation = useLocation<any>();
    const [teamData, setTeamData] = useState<{
        teamName: string;
        teamId: string;
        slackChannel: string;
    }>(teamDataLocation.state);

    useEffect(() => {
        if (isMounted.current) {
            setLocationChange(true);
            setTeamData(teamDataLocation.state);
        } else {
            isMounted.current = true;
        }
    }, [teamDataLocation]);

    useEffect(() => {
        const { teamName, teamId, slackChannel } = teamData;
        if (
            newTeamName?.renameTeam.name &&
            newTeamName?.renameTeam.name !== teamName &&
            !locationChange
        ) {
            setTeamData({
                teamName: newTeamName.renameTeam.name,
                teamId,
                slackChannel,
            });
        } else if (
            newSlackName?.updateSlackChannel.slackChannel &&
            newSlackName?.updateSlackChannel.slackChannel !== slackChannel &&
            !locationChange
        ) {
            setTeamData({
                teamName,
                teamId,
                slackChannel: newSlackName.updateSlackChannel.slackChannel,
            });
        }
    }, [newTeamName, newSlackName, teamData, locationChange]);

    const handleSubmit = useCallback(
        (teamName: string, slackChannel: string) => {
            setLocationChange(false);
            if (slackChannel !== teamData.slackChannel && slackChannel !== undefined) {
                updateSlackChannel({
                    variables: {
                        input: {
                            workspaceId: workspaceId,
                            teamId: teamData.teamId,
                            slackChannel: slackChannel,
                        },
                    },
                });
            }

            if (teamName !== teamData.teamName && teamName !== undefined) {
                renameTeam({
                    variables: {
                        input: {
                            workspaceId: workspaceId,
                            name: teamName,
                            teamId: teamData.teamId,
                        },
                    },
                });
            }
        },
        [renameTeam, teamData, updateSlackChannel, workspaceId]
    );

    return (
        <div style={{ marginRight: '10%' }}>
            <EditTeamForm
                onSubmit={(d) => {
                    handleSubmit(d.teamName, d.slackChannel);
                }}
                callErrors={error?.graphQLErrors?.map((e) => e.message)}
                isLoading={loading || slackInputLoading}
                teamName={teamData.teamName}
                slackChannel={teamData.slackChannel}
            />

            <TeamMembersList
                workspaceId={workspaceId}
                teamId={teamData.teamId}
                teamName={teamData.teamName}
            />
        </div>
    );
};

export default TeamSettings;
