import { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';
import { logoutWithGoogle } from '../../../services/Auth';

const StyledContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
interface ContainerProps {
    showLoader: boolean;
    showRedirect: boolean | undefined;
}
const BlockContainer = styled.div<ContainerProps>`
    background: white;
    border-radius: 3px;
    @media (min-width: 768px) {
        width: 672px;
        height: ${(props) => (props.showLoader || props.showRedirect ? '403px' : '336px')};
    }
`;

const CenterBlockContainer = styled.div`
    background: white;
    @media (min-width: 768px) {
        width: 632.5px;
        height: 231px;
    }
`;

interface Props {
    title: string;
    message: string;
    icon: string;
    showLoader: boolean;
    showRedirect?: boolean;
}

const LoadingDialog: FC<Props> = ({ title, message, icon, showLoader, showRedirect }) => {
    const history = useHistory();
    return (
        <StyledContainer data-testid="LoadingDialog_test">
            <BlockContainer
                showLoader={showLoader}
                showRedirect={showRedirect}
                data-testid="Dialog_blockContainer"
            >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <div style={{ marginTop: '50px' }}>
                            <Icon
                                pro
                                icon={icon}
                                color={Colors.primary}
                                size={FontAwesomeSize.threeX}
                                margin="0px"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <CenterBlockContainer data-testid="Dialog_centerBlockContainer">
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <h4 style={{ color: 'rgb(34, 53, 84)', textAlign: 'center' }}>
                                        {title}
                                    </h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <p
                                        style={{
                                            color: 'rgb(34, 53, 84)',
                                            textAlign: 'center',
                                            marginBottom: '0px',
                                        }}
                                        className="body-medium"
                                    >
                                        {message}
                                    </p>
                                </Grid>
                                {showLoader && (
                                    <Grid item xs={12}>
                                        <div style={{ marginTop: '30px' }}>
                                            <Icon
                                                pro
                                                className="fa-spin"
                                                icon="faCircleNotch"
                                                color={Colors.primary}
                                                size={FontAwesomeSize.threeX}
                                                margin="0px"
                                            />
                                        </div>
                                    </Grid>
                                )}

                                {showRedirect && (
                                    <Grid item xs={12}>
                                        <div>
                                            <p
                                                className="body-medium"
                                                style={{
                                                    color: 'rgb(34, 53, 84)',
                                                    textAlign: 'center',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Want to try to log in with a different email?
                                                <button
                                                    className="body-medium"
                                                    style={{
                                                        background: 'transparent',
                                                        border: 'none',
                                                        color: '#069',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        logoutWithGoogle();
                                                        history.push({
                                                            pathname: '/login',
                                                            state: { from: '', fromSearch: '' },
                                                        });
                                                    }}
                                                >
                                                    Go back
                                                </button>
                                                to log in page.
                                            </p>
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12}>
                                            <p
                                                className="bold-small"
                                                style={{
                                                    color: 'rgb(34, 53, 84)',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Did you know?
                                            </p>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <p
                                                className="body-small"
                                                style={{
                                                    color: 'rgb(144, 154, 169)',
                                                    textAlign: 'center',
                                                    margin: '0px',
                                                }}
                                            >
                                                The word Electric, comes from the Greek word for
                                                Amber. Greeks discovered that by rubbing Amber on
                                                cats fur it would create a static charge.
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CenterBlockContainer>
                    </Grid>
                </Grid>
            </BlockContainer>
        </StyledContainer>
    );
};

export default LoadingDialog;
