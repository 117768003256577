import { ReactNode } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { useAppContext } from '../AppContext';
import { useLoginToWorkspace } from '../services/Auth';
import LoadingDialog from '../components/Loader/LoadingDialog';

interface Props {
    children: ReactNode;
}

const WorkspaceAuth = ({ children }: Props) => {
    const { params } = useRouteMatch<{ slug: string }>();

    const { user, authToken, idToken, setAuthToken, setUser, setWorkspaceId, setWorkspaceSlug } =
        useAppContext();

    const foundWorkspace = user!.workspaces.find((w) => w.slug === params.slug);

    const { data, loading } = useLoginToWorkspace(
        {
            uid: user!.id,
            wid: foundWorkspace?.workspaceId || '',
        },
        (auth) => {
            setAuthToken(auth.loginToWorkspace.token);
            setUser(auth.loginToWorkspace.user);
            setWorkspaceId(foundWorkspace?.workspaceId || '');
            setWorkspaceSlug(foundWorkspace?.slug || '');
        },
        !foundWorkspace || !user || !authToken || !idToken
    );

    if (!foundWorkspace || (!loading && data && !data.loginToWorkspace)) {
        return <Redirect to="/404" />;
    }

    if (loading) {
        return (
            <LoadingDialog
                title="Authenticating your workspace"
                message="Hold tight - we are now verifying your access to the workspace"
                icon="faDirections"
                showLoader
            />
        );
    }

    return <>{children}</>;
};

export default WorkspaceAuth;
