import { FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

/** Registering Imports from ChartJS */
Chart.register(...registerables);

interface HourlyNode {
    timestamp: number;
    ticketAmount: number;
}

interface Props {
    hourlyLinegraphData: HourlyNode[] | null;
    title: string;
    who: string | null;
    dataLabel: string;
}

const Linegraph: FC<Props> = (props: Props) => {
    const { hourlyLinegraphData, title, who, dataLabel } = props;
    const [graphTitle, setGraphTitle] = useState<string>(title);
    const productivityData = Array.apply(null, Array(23)).map(() => {
        return 0;
    });
    if (hourlyLinegraphData) {
        hourlyLinegraphData.forEach((dataPoint) => {
            const { ticketAmount, timestamp } = dataPoint;
            let date = new Date(timestamp * 1000);
            const hour = date.getHours();
            productivityData[hour] = ticketAmount;
        });
    }

    useEffect(() => {
        setGraphTitle(title);
    }, [title]);

    useEffect(() => {
        if (who) {
            setGraphTitle(title + ` by ${who}`);
        }
    }, [title, who]);

    const labels = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
    ];
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: graphTitle,
            },
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: dataLabel,
                data: productivityData,
                borderColor: 'rgb(24, 107, 237)',
                backgroundColor: 'rgba(24, 107, 237, 0.5)',
            },
        ],
    };
    return (
        <div style={{ marginTop: '20px', backgroundColor: 'rgb(255, 255, 255)', height: '392px' }}>
            <Line height="65px" options={options} data={data} />
        </div>
    );
};

export default Linegraph;
