import dayjs from 'dayjs';
import { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Colors, Size } from '@partnerhero/new_design_system.utils.enums';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import { Dropdown } from '@partnerhero/new_design_system.form_elements.dropdown';
import { Label } from '@partnerhero/new_design_system.buttons.label';
import CalendarPicker from '../../../components/CalendarPicker';
import ExportDataContainer from './ExportDataContainer';
import DashboardLoader from '../../../components/Loader/DashboardLoader';
import RequestFormCardContainer from './RequestFormCards';
import MetricsStatsContainer from './MetricsStats';
import { useAppContext } from '../../../AppContext';
import { updateSelectedLink } from '../../../utils/verticalNavbar';
import { useGetProductivityCrunch, useGetHourlyCrunch } from '../../../services/Productivity';
import { useGetTeamsByWorkspaceId } from '../../../services/Team';
import { useLazyTeamMembersFetch } from '../../../services/TeamMember';
import { TeamsByWorkspaceId_getAllTeamsFromWorkspace } from '../../../queries/types/TeamsByWorkspaceId';
import { TicketStatus } from '../../../queries/Productivity';
import { Button } from '@partnerhero/new_design_system.buttons.button';

const mainFilter = [
    { value: 'workspace', text: 'Workspace' },
    { value: 'team', text: 'Team' },
];

const NotificationParagraph = styled.p`
    width: 100%;
    heigth: 100%;
`;

interface Card {
    id: string;
    title: string;
    amount: number;
}

interface DashboardOption {
    value: string;
    text: string;
}

interface HourlyNode {
    timestamp: number;
    ticketAmount: number;
}

const Statistics: FC = () => {
    const [date, setDate] = useState<string>('Today');
    const today = dayjs().startOf('day');
    const yesterday = today.subtract(1, 'day').startOf('day');
    const startOfDay = today.startOf('day').unix();
    const endOfDay = today.endOf('day').unix();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const todayFormatted = today.format('DD/MM/YYYY');
    const yesterdayFormatted = yesterday.format('DD/MM/YYYY');
    const { selectedNavLink, setAppBar, setSelectedNavLink, workspaceId } = useAppContext();

    const [linegraphTitle, setLinegraphTitle] = useState<string>(
        'Total Number of Tickets Solved Today'
    );
    const [linegraphNameTitle, setLinegraphNameTitle] = useState<string | null>(null);

    const [notificationIsOpen, setNotificationIsOpen] = useState<boolean>(true);

    const [allTeams, setAllTeams] = useState<TeamsByWorkspaceId_getAllTeamsFromWorkspace[]>([]);

    const [teamIsSelected, setTeamIsSelected] = useState<boolean>(false);
    const [teamNameSelected, setTeamNameSelected] = useState<string>('');
    const [memberIsSelected, setMemberIsSelected] = useState<boolean>(false);
    const [productivityCards, setProductivityCards] = useState<Card[] | null>(null);
    const [hourlyLinegraphData, setHourlyLinegraphData] = useState<HourlyNode[] | null>(null);
    const [productivityCardsValues, setProductivityCardsValues] = useState<boolean>(false);
    const [teamsFilter, setTeamsFilter] = useState<DashboardOption[]>([{ text: '', value: '' }]);
    const [membersFilter, setMembersFilter] = useState<DashboardOption[]>([
        { value: 'allMembers', text: 'All Members' },
    ]);

    const [membersData, setMembersData] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
    const [selectedMember, setSelectedMember] = useState<string | null>(null);
    const [beginTime, setBeginTime] = useState<number>(startOfDay);
    const [endTime, setEndTime] = useState<number>(endOfDay);
    const [fetchError, setFetchError] = useState<boolean>(false);
    const [chosenOption, setChosenOption] = useState<{ value: string; text: string }>(
        mainFilter[0]
    );
    const [defaultTeam, setDefaultTeam] = useState<string>('');
    const [chosenTeamOption, setChosenTeamOption] = useState<
        | {
              value: string;
              text: string;
          }
        | undefined
    >(undefined);
    const [chosenMemberOption, setChosenMemberOption] = useState<{
        value: string;
        text: string;
    }>(membersFilter[0]);

    /**
     * Set as object type 'any' because of a DS limitation on the dropdown component
     */
    const mainDropdownRef = useRef<any>(null);
    const membersDropdownRef = useRef<any>(null);
    const teamsDropdownRef = useRef<any>(null);

    // Date Picker State Managers
    const [dateButtonClicked, setDateButtonClicked] = useState<boolean>(false);

    /**
     * Fetch Teams by Workspace Id
     */
    const { error: teamsError } = useGetTeamsByWorkspaceId(
        {
            workspaceId,
        },
        (data) => {
            setAllTeams(data.getAllTeamsFromWorkspace);
        }
    );

    useEffect(() => {
        productivityCards?.forEach((element) => {
            if (element.amount > 0) {
                setProductivityCardsValues(true);
            }
        });
    }, [productivityCards]);

    /**
     * Fetch Team Members from selected Team
     */
    const [getTeamMembers, { data: teamMembers, error: teamMemberError }] =
        useLazyTeamMembersFetch();

    /**
     * Set Team Members List
     */
    useEffect(() => {
        setProductivityCardsValues(false);
        setMembersData(false);
        if (teamMembers) {
            let membersList = teamMembers?.getTeamMembersFromTeam?.map((member) => ({
                text: `${member.firstName} ${member.lastName}`,
                value: member.id,
            }));
            setMembersFilter([{ value: 'allMembers', text: 'All Members' }, ...membersList]);
            teamMembers?.getTeamMembersFromTeam.length < 1
                ? setMembersData(true)
                : setMembersData(false);
        }
    }, [teamMembers]);

    /**
     * Set Teams List
     */
    useEffect(() => {
        setProductivityCardsValues(false);
        if (allTeams) {
            const teams = allTeams.map((team) => ({
                value: team.id,
                text: team.name,
            }));
            setTeamsFilter(teams);
        }
    }, [allTeams]);

    /**
     * Productivity Crunch
     */
    const { error, refetch, loading } = useGetProductivityCrunch(
        {
            workspaceId,
            teamId: selectedTeam,
            teamMemberId: selectedMember,
            beginTime,
            endTime,
        },
        (data) => {
            if (data) {
                const { open, pending, onHold, solved } = data.productivityCrunch;
                setProductivityCards([
                    {
                        id: 'solved',
                        title: 'Tickets Solved',
                        amount: solved,
                    },
                    {
                        id: 'open',
                        title: 'Tickets Open',
                        amount: open,
                    },
                    {
                        id: 'onHold',
                        title: 'Tickets On Hold',
                        amount: onHold,
                    },
                    {
                        id: 'pending',
                        title: 'Tickets Pending',
                        amount: pending,
                    },
                ]);
            }
        }
    );

    /**
     * Hourly Crunch for Linegraph
     */

    const { error: hourlyCrunchError, loading: hourlyCrunchLoading } = useGetHourlyCrunch(
        {
            workspaceId,
            teamId: selectedTeam,
            teamMemberId: selectedMember,
            beginTime,
            endTime,
            status: TicketStatus.solved,
        },
        (data) => {
            if (data?.getHourlyCrunch) {
                const { data: hourlyProductivityData } = data.getHourlyCrunch;
                if (hourlyProductivityData.length > 0) {
                    const graphData = hourlyProductivityData.map((hourlyData) => {
                        const { timestamp, ticketAmount } = hourlyData;
                        return { timestamp, ticketAmount };
                    });
                    setHourlyLinegraphData(graphData);
                } else {
                    setHourlyLinegraphData(null);
                }
            }
        }
    );

    /**
     * App Bar Setup
     */
    useEffect(() => {
        const linkInfo = updateSelectedLink('dashboard');
        if (linkInfo) {
            if (selectedNavLink !== linkInfo.index) {
                setAppBar({ icon: linkInfo.icon, title: linkInfo.title });
                setSelectedNavLink(linkInfo.index);
            }
        }
    }, [setSelectedNavLink, setAppBar, selectedNavLink]);

    /**
     * Change on the selectedDate
     */

    const onDateChange = (selectedDate: dayjs.Dayjs) => {
        const selectedDateFormatted = selectedDate.format('DD/MM/YYYY');
        if (selectedDateFormatted === todayFormatted) {
            setDate('Today');
            setLinegraphTitle('Total Number of Tickets Solved Today');
        } else if (selectedDateFormatted === yesterdayFormatted) {
            setDate('Yesterday');
            setLinegraphTitle('Total Number of Tickets Solved Yesterday');
        } else {
            const formattedDate = selectedDate.format('DD/MM/YYYY');
            setDate(formattedDate);
            setLinegraphTitle(`Total Number of Tickets Solved ${formattedDate}`);
        }

        setBeginTime(selectedDate.startOf('day').unix());
        setEndTime(selectedDate.endOf('day').unix());
        refetch();
    };

    /**
     * Check for Errors on Fetches
     */
    useEffect(() => {
        if (error || teamsError || teamMemberError) {
            setFetchError(true);
        } else {
            setFetchError(false);
        }
    }, [error, teamsError, teamMemberError]);

    /**
     * Reset Filters
     */

    const initialFilter = (teamId: string) => {
        setProductivityCardsValues(false);
        setTeamIsSelected(true);
        setSelectedTeam(teamsFilter[0].value);
        setChosenTeamOption(teamsFilter[0]);
        teamsDropdownRef.current?.setIndex(0);
        getTeamMembers({
            variables: {
                workspaceId: workspaceId,
                teamId: teamId,
            },
        });
    };

    const resetFilters = () => {
        setChosenMemberOption(membersFilter[0]);
        setProductivityCardsValues(false);
        setSelectedTeam(null);
        setSelectedMember(null);
        setTeamIsSelected(false);
        setMemberIsSelected(false);
        mainDropdownRef.current?.setIndex(0);
        setChosenOption(mainFilter[0]);
        setMembersData(false);
        setLinegraphNameTitle(null);
        refetch();
    };

    return (
        <>
            <Notification
                isOpened={notificationIsOpen}
                closeIconProps={{
                    onClick: () => setNotificationIsOpen(false),
                    icon: 'faTimes',
                }}
                size={Size.small}
                title="Welcome to the New Look Beacon!"
                text={
                    <NotificationParagraph>
                        <p>
                            Hello there (General Kenobi). What you are looking at is the first of
                            many improvements to Beacon.
                        </p>
                        <p style={{ marginTop: '2rem' }}>
                            Don’t worry the old Beacon will still work, as it always has, but over
                            the coming months we will be adding more and more to this product, and
                            with your feedback we’ll be making continual improvements to your
                            experience.
                        </p>
                    </NotificationParagraph>
                }
                leftIconProps={{ icon: 'faSparkles', pro: true }}
                notificationBg={Colors.primary}
                notificationAppBar
            />
            <Grid container justifyContent="space-between">
                <Grid container item xs={10}>
                    <Grid>
                        <div style={{ width: '27rem' }}>
                            <Dropdown
                                ref={mainDropdownRef}
                                smallDropdown
                                margin="0 2rem"
                                options={mainFilter}
                                selectedOption={chosenOption}
                                onClick={(selectedFilter) => {
                                    setChosenOption(selectedFilter);
                                    if (selectedFilter === mainFilter[1]) {
                                        setDefaultTeam(teamsFilter[0].text);
                                        setLinegraphNameTitle(teamsFilter[0].text);
                                        initialFilter(teamsFilter[0].value);
                                    } else {
                                        resetFilters();
                                    }
                                }}
                                placeholder={mainFilter[0].text}
                                disabled={!allTeams.length}
                            />
                        </div>
                    </Grid>

                    {teamIsSelected && teamsFilter && (
                        <>
                            <Grid>
                                <div style={{ width: '27rem' }}>
                                    <Dropdown
                                        ref={teamsDropdownRef}
                                        smallDropdown
                                        margin="0 20px"
                                        options={teamsFilter}
                                        selectedOption={chosenTeamOption}
                                        onClick={(selectedFilter) => {
                                            setChosenTeamOption(selectedFilter);
                                            setSelectedTeam(selectedFilter.value);
                                            setTeamNameSelected(selectedFilter.text);
                                            setLinegraphNameTitle(selectedFilter.text);
                                            setSelectedMember(null);
                                            setChosenMemberOption(membersFilter[0]);
                                            membersDropdownRef.current?.setIndex(0);
                                            setMemberIsSelected(false);
                                            refetch();
                                            if (selectedFilter) {
                                                getTeamMembers({
                                                    variables: {
                                                        workspaceId: workspaceId,
                                                        teamId: selectedFilter.value,
                                                    },
                                                });
                                            }
                                        }}
                                        placeholder={teamsFilter[0].text}
                                    />
                                </div>
                            </Grid>
                            <Grid>
                                <div style={{ width: '27rem' }}>
                                    <Dropdown
                                        ref={membersDropdownRef}
                                        smallDropdown
                                        margin="0 20px"
                                        options={membersFilter}
                                        selectedOption={chosenMemberOption}
                                        onClick={(selectedFilter) => {
                                            setChosenMemberOption(selectedFilter);
                                            if (selectedFilter !== membersFilter[0]) {
                                                setMemberIsSelected(true);
                                                setSelectedMember(selectedFilter.value);
                                                setLinegraphNameTitle(selectedFilter.text);
                                                refetch();
                                            } else {
                                                setSelectedMember(null);
                                                setLinegraphNameTitle(teamNameSelected);
                                                setMemberIsSelected(false);
                                            }
                                        }}
                                        placeholder={membersFilter[0].text}
                                    />
                                </div>
                            </Grid>
                        </>
                    )}

                    {memberIsSelected && (
                        <Grid>
                            <div style={{ marginRight: '5rem' }}>
                                <Label
                                    labelText="Reset Filter"
                                    labelColor={Colors.primary}
                                    fullWidth
                                    margin="10px"
                                    onClick={resetFilters}
                                />
                            </div>
                        </Grid>
                    )}

                    <Grid>
                        <Button
                            type="button"
                            onClick={() => {
                                setDateButtonClicked(!dateButtonClicked);
                            }}
                            buttonColor={Colors.primary}
                            size={Size.small}
                            rightIcon
                            iconText={`Date: ${date}`}
                            iconProps={{
                                icon: 'faCalendar',
                                pro: true,
                                color: Colors.white,
                                margin: '0',
                            }}
                            margin={'0 0rem 0 0'}
                        />
                        {dateButtonClicked && (
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: '10',
                                    marginTop: '10px',
                                }}
                            >
                                <CalendarPicker
                                    currentDate={currentDate}
                                    onClick={(newDate: Date) => {
                                        onDateChange(dayjs(newDate));
                                        setCurrentDate(newDate);
                                        setDateButtonClicked(!dateButtonClicked);
                                    }}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>

                <Grid item>
                    <ExportDataContainer
                        workspaceId={workspaceId}
                        teamId={selectedTeam}
                        teamName={teamNameSelected || defaultTeam}
                        teamMemberId={selectedMember}
                        beginTime={beginTime}
                        endTime={endTime}
                        dataAvailable={productivityCardsValues}
                        workspaceSelected={!!selectedTeam}
                        chosenMemberOption={chosenMemberOption.text}
                        day={date}
                    />
                </Grid>
            </Grid>

            <>
                {loading || hourlyCrunchLoading ? (
                    <DashboardLoader />
                ) : (
                    <MetricsStatsContainer
                        fetchError={fetchError}
                        membersData={membersData}
                        allTeams={allTeams}
                        chosenOption={chosenOption}
                        membersFilter={membersFilter}
                        productivityCards={productivityCards}
                        loading={loading}
                        hourlyCrunchError={hourlyCrunchError}
                        hourlyLinegraphData={hourlyLinegraphData}
                        linegraphNameTitle={linegraphNameTitle}
                        linegraphTitle={linegraphTitle}
                    />
                )}
            </>
            <Grid container direction="row" wrap="wrap" justifyContent="center">
                <RequestFormCardContainer />
            </Grid>
        </>
    );
};

export default Statistics;
