import { FC } from 'react';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

interface Props {
    onClick(date: Date): void;
    currentDate: Date;
}

const CalendarPicker: FC<Props> = ({ onClick, currentDate }) => {
    const today = new Date();
    return (
        <Calendar
            className="calendar"
            value={currentDate}
            defaultValue={today}
            defaultView={'month'}
            maxDetail={'month'}
            maxDate={today}
            onClickDay={(d) => onClick(d)}
            next2Label={null}
            prev2Label={null}
            prevLabel={
                <Icon
                    pro
                    icon="faArrowLeft"
                    color={Colors.black}
                    size={FontAwesomeSize.sm}
                    margin="0px"
                />
            }
            nextLabel={
                <Icon
                    pro
                    icon="faArrowRight"
                    color={Colors.black}
                    size={FontAwesomeSize.sm}
                    margin="0px"
                />
            }
            prevAriaLabel={'Previous'}
            nextAriaLabel={'next'}
        />
    );
};

export default CalendarPicker;
