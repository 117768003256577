import { gql } from '@apollo/client';
import { User } from './Users';
import { WorkspaceMember } from './WorkspaceMembers';

export const LoginResult = gql`
    fragment LoginResult on LoginResult {
        user {
            ...User
        }
        workspaceMember {
            ...WorkspaceMember
        }
        token
    }
    ${User}
    ${WorkspaceMember}
`;

export const LOGIN = gql`
    query Login($uid: String!) {
        login(id: $uid) {
            ...LoginResult
        }
    }
    ${LoginResult}
`;

export const LOGIN_TO_WORKSPACE = gql`
    query LoginToWorkspace($uid: String!, $wid: String!) {
        loginToWorkspace(id: $uid, workspaceId: $wid) {
            ...LoginResult
        }
    }
    ${LoginResult}
`;
