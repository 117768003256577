import { Switch, Route, useRouteMatch, Redirect } from 'react-router';
import SettingsPage from './SettingsPage';
import { Router as TeamSettingsRouter } from './Teams';
import { Router as WorkspaceMembersRouter } from './WorkspaceMembers';
import { Router as PolicyLinksRouter } from './PolicyLinks';

// Route: /workspaces/:slug/settings
const Router = () => {
    const { url } = useRouteMatch();
    return (
        <SettingsPage>
            <Switch>
                <Route exact path={url}>
                    <Redirect to={`${url}/teams`} />
                </Route>
                <Route path={`${url}/teams`} component={TeamSettingsRouter} />
                <Route path={`${url}/workspace-members`} component={WorkspaceMembersRouter} />
                <Route path={`${url}/policies`} component={PolicyLinksRouter} />
                <Route render={() => <Redirect to={url} />} />
            </Switch>
        </SettingsPage>
    );
};

export default Router;
