import { FC } from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import NewWorkspace from './New';
import WorkspaceAuth from '../../auth/WorkspaceAuth';
import WorkspaceRouter from './View/Router';
import WorkspaceRedirect from './Redirect';

interface Props extends RouteComponentProps {}

// Route: /workspaces
const Router: FC<Props> = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={WorkspaceRedirect} />
            <Route path={`${match.url}/new`} component={NewWorkspace} />
            <Route
                path={`${match.url}/:slug`}
                render={() => (
                    <WorkspaceAuth>
                        <WorkspaceRouter />
                    </WorkspaceAuth>
                )}
            />
            <Redirect to="/404" />
        </Switch>
    );
};

export default Router;
