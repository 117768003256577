import { useMutation, useQuery } from '@apollo/client';

import { useAppContext } from '../AppContext';
import {
    CreateWorkspaceInvitation,
    CreateWorkspaceInvitationVariables,
} from '../queries/types/CreateWorkspaceInvitation';

import {
    GetActiveInvitationsForWorkspace,
    GetActiveInvitationsForWorkspaceVariables,
} from '../queries/types/GetActiveInvitationsForWorkspace';

import {
    CREATE_WORKSPACE_INVITATION,
    GET_ACTIVE_INVITATIONS_FOR_WORKSPACE,
} from '../queries/Invitations';

export const useCreateWorkspaceInvitation = () => {
    const { idToken, authToken } = useAppContext();
    return useMutation<CreateWorkspaceInvitation, CreateWorkspaceInvitationVariables>(
        CREATE_WORKSPACE_INVITATION,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                    Authorization: `Bearer ${authToken || ''}`,
                },
            },
            onError: () => {
                // This is intentional
            },
        }
    );
};

export const useGetActiveInvitations = (
    variables: GetActiveInvitationsForWorkspaceVariables,
    onCompleted: (data: GetActiveInvitationsForWorkspace) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useQuery<GetActiveInvitationsForWorkspace, GetActiveInvitationsForWorkspaceVariables>(
        GET_ACTIVE_INVITATIONS_FOR_WORKSPACE,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                    Authorization: `Bearer ${authToken || ''}`,
                },
            },
            variables,
            onCompleted,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        }
    );
};
