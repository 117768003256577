import { FC, ReactNode, ComponentType } from 'react';
import styled from 'styled-components';

const StyledItem = styled.div`
    flex-grow: 1;
`;

const StyledContainer = styled.div`
    display: flex;
    height: 100%;
    background-color: rgb(236, 243, 254);
    width: 100%;
    @media (min-width: 1024px) {
        background-image: url('/assets/new-assets/bullseye-bg.svg'),
            url('/assets/new-assets/bullseye-bg (1).svg');
        background-repeat: no-repeat, no-repeat;
        background-position: top right, bottom left;
    }
    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        margin: auto 0;
    }
`;
const CenterBlock = styled(StyledItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 6rem;
    @media (min-width: 768px) {
        margin: auto 0;
    }
`;

interface Props {
    children: ReactNode;
    centerComponent?: ComponentType<any>;
}

const AuthPage: FC<Props> = ({ children, centerComponent }) => {
    const CenterComponent = centerComponent || CenterBlock;

    return (
        <StyledContainer data-testid="AuthPageContainer">
            <CenterComponent data-testid="CenterComponent">{children}</CenterComponent>
        </StyledContainer>
    );
};

export default AuthPage;
