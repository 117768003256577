import { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import {
    boldSmall,
    bodyLarge,
    bodyMedium,
    bodySmall,
    defaultHeaderStyles,
    defaultNavigationStyles,
    defaultTypographyStyles,
    header1Style,
    header2Style,
    header3Style,
    header4Style,
    header5Style,
    boldLarge,
} from '@partnerhero/new_design_system.utils.typography';
import { GlobalStyles } from '@partnerhero/new_design_system.utils.global_styles';
import { Colors } from '@partnerhero/new_design_system.utils.enums';

const Styles = createGlobalStyle`
    html {
        margin: 0;
        font-size: 62.5%;
    }

    body {
        margin: 0;
        padding: 0;
        height: 100vh;
        background: #F0F6FE;
        font-family: Inter-Regular;
        font-weight: normal;
        overflow: hidden;
    }

    main {
        overflow: auto;
    }

    h1,h2,h3,h4,h5,h6 {
        ${defaultHeaderStyles}
    }

    h1,h2,h3,h4,h5,h6,p {
        ${defaultTypographyStyles}
    }

    h1 {
        ${header1Style}
    }
    h2 {
        ${header2Style}
    }
    h3 {
        ${header3Style}
    }
    h4 {
        ${header4Style}
    }
    h5 {
        ${header5Style}
    }
    h6 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    a {
        ${defaultNavigationStyles}
        &:link {
            color: #186bed;
        }
    }

    .bold-large {
        ${boldLarge}
    },
    .body-large {
        ${bodyLarge}
    }
    .body-medium {
        ${bodyMedium}
    }
    .body-small {
        ${bodySmall}
    }
    .bold-small {
        ${boldSmall}
    }
    .appbar-padding {
        padding-top: 6px;
        padding-bottom: 7px;
    }

    .calendar {
        border-style: solid;
        border-top-width: 5px;
        border-top-color: #186bed;
        font-family: Inter-SemiBold;
    }

    .react-calendar__month-view__days__day--weekend {
        color: #000;
    }

    .react-calendar__tile--now {
        background: #9FC2F7;
        color: #fff;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: #124BA8;   
    }

    .react-calendar__tile--active {
        background: #006edc;
        color: white;
    }

    .react-calendar__navigation__label__labelText {
        font-family: Inter-SemiBold;
    }
`;

const BaselineCSS: FC = () => {
    return (
        <>
            <GlobalStyles bodyBackground={Colors.backgroundPrimaryLightest} />
            <Styles />
        </>
    );
};

export default BaselineCSS;
