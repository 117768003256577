import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors, FontAwesomeSize, Size } from '@partnerhero/new_design_system.utils.enums';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import {
    useCreateWorkspaceInvitation,
    useGetActiveInvitations,
} from '../../../../services/Invitation';
import InviteWorkspaceMemberForm from '../../../../components/InviteWorkspaceMemberForm';
import InvitationsList from '../../../../components/InvitationsList';
import WorkspaceMembersList from '../../../../components/WorkspaceMembersList';

import { useAppContext } from '../../../../AppContext';

const Panel = styled.div`
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
`;

const WorkspaceMembersPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20rem;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface InvitationOption {
    Email: string;
}

const WorkspaceMembersPage: FC = () => {
    const { workspaceId } = useAppContext();
    const [formErrors, setFormErrors] = useState<string[] | undefined>([]);
    const [isFormOpen, setFormOpen] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [CreateWorkspaceInvitation, { loading, error, data }] = useCreateWorkspaceInvitation();
    const [emailInvite, setEmailInvite] = useState<string>('');
    const [invitationsList, setInvitationsList] = useState<InvitationOption[] | undefined>(
        undefined
    );
    const [hasRefetched, setHasRefetched] = useState(true);

    const { refetch } = useGetActiveInvitations(
        {
            workspaceId: workspaceId,
        },
        (value) => {
            let invitations = value?.getActiveInvitationsForWorkspace?.map((invitation) => {
                return { Email: invitation.email };
            });
            if (!!invitations.length) {
                setInvitationsList(invitations);
            } else {
                setInvitationsList(undefined);
            }
        }
    );

    const handleSubmit = (invitationEmail: string) => {
        setFormErrors([]);
        if (/@partnerhero\.com$/.test(invitationEmail)) {
            CreateWorkspaceInvitation({
                variables: {
                    input: {
                        email: invitationEmail,
                        workspaceId,
                    },
                },
            });
        } else {
            handleError(['Invalid Email Address, must contain @partnerhero.com']);
        }
    };

    const closeForm = () => {
        setFormOpen(false);
        setFormErrors([]);
    };

    const handleError = (errors: string[] | undefined) => {
        setFormErrors(errors);
    };

    useEffect(() => {
        handleError(error?.graphQLErrors?.map((e) => e.message));
    }, [error]);

    useEffect(() => {
        if (data && !error) {
            setEmailInvite(data.createWorkspaceInvitation.email);
            closeForm();
            setOpenNotification(true);
            setHasRefetched(false);
        }
    }, [data, error]);

    useEffect(() => {
        if (!hasRefetched) {
            refetch();
            setHasRefetched(true);
        }
    }, [hasRefetched, refetch]);

    return (
        <WorkspaceMembersPageContainer>
            <Panel
                style={{
                    justifyContent: 'space-between',
                    height: '8rem',
                    padding: '0 2rem',
                    boxShadow: 'rgb(100, 100, 111, 0.2) 0px 2px 3px 0px',
                }}
            >
                <IconAndText>
                    <Icon
                        pro
                        icon={'faUsersCrown'}
                        color={Colors.primary}
                        size={FontAwesomeSize.twoX}
                        margin="0 0 0 2rem"
                    />
                    <h5 style={{ color: 'rgb(34, 53, 84)', margin: '0 0 0 5rem' }}>
                        Workspace Members
                    </h5>
                </IconAndText>
                <Button
                    size={Size.small}
                    buttonColor={Colors.primary}
                    onClick={() => setFormOpen(true)}
                    type="button"
                    disabled={isFormOpen}
                    buttonText="Invite New"
                    margin="0"
                />
            </Panel>
            <Notification
                notificationAppBar
                size={Size.small}
                isOpened={openNotification}
                closeIconProps={{ onClick: () => setOpenNotification(false), icon: 'faTimes' }}
                text={
                    <p style={{ marginRight: '2rem' }}>
                        You’ve sent a Workspace Invite to {emailInvite}
                    </p>
                }
                leftIconProps={{
                    icon: 'faThumbsUp',
                    pro: true,
                }}
                notificationBg={Colors.success}
            />
            {isFormOpen && (
                <Panel style={{ boxShadow: 'rgb(100, 100, 111, 0.2) 0px 2px 3px 0px' }}>
                    <InviteWorkspaceMemberForm
                        onSubmit={(d) => {
                            handleSubmit(d.invitationEmail);
                        }}
                        onCancel={closeForm}
                        callErrors={formErrors}
                        isLoading={loading}
                    />
                </Panel>
            )}
            <WorkspaceMembersList />
            {invitationsList && <InvitationsList invitationsList={invitationsList} />}
        </WorkspaceMembersPageContainer>
    );
};

export default WorkspaceMembersPage;
