import styled from 'styled-components';
import { FC, useState, useCallback, useEffect } from 'react';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Dropdown } from '@partnerhero/new_design_system.form_elements.dropdown';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import NewTeamMemberForm from '../NewTeamMemberForm';
import TeamListForm from '../../containers/Teams';
import { Colors, Size, FontAwesomeSize } from '@partnerhero/new_design_system.utils.enums';
import { Icon } from '@partnerhero/new_design_system.icons.icon';

import { useCreateTeamMember, useGetTeamMembers } from '../../services/TeamMember';

const DropdownFilters = [
    { value: 'allMembers', text: 'All Members' },
    { value: 'active', text: 'Active' },
    { value: 'inactive', text: 'Inactive' },
];

const ButtonsContainer = styled.div`
    display: flex;
    height: 100%;
`;

interface TeamMemberOption {
    'First Name': string;
    'Last Name': string;
    'Zendesk Email': string;
    id: string;
    teamId: string;
}

interface Props {
    workspaceId: string;
    teamId: string;
    teamName?: string;
}

const TeamMembersList: FC<Props> = ({ workspaceId, teamId, teamName }) => {
    const [newMemberData, setNewMemberData] = useState<any>();
    const [openNotification, setOpenNotification] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [fullName, setFullName] = useState<string>('');
    const [teamMembersFirstFetch, setTeamMembersFirstFetch] = useState<boolean>(true);

    const [CreateTeamMember, { loading }] = useCreateTeamMember(
        (data) => {
            setNewMemberData(data.createTeamMember);
            closeForm(true);
        },
        (err) => {
            handleError(err?.graphQLErrors.map((e) => e.message));
        }
    );
    const [isUpdate, setUpdate] = useState(true);
    const [activeAddButton, setActiveAddButton] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [teamMember, setTeamMemberList] = useState<TeamMemberOption[] | undefined>([]);

    const { refetch, loading: firstLoading } = useGetTeamMembers(
        {
            workspaceId: workspaceId,
            teamId: teamId,
        },
        (value) => {
            let membersList = value?.getTeamMembersFromTeam?.map((member) => {
                return {
                    'First Name': member.firstName,
                    'Last Name': member.lastName,
                    'Zendesk Email': member.zendeskEmail,
                    id: member.id,
                    teamId: member.teamId,
                };
            });
            setTeamMemberList(membersList);
        }
    );

    const handleError = useCallback((errors: string[]) => {
        setFormErrors(errors);
    }, []);

    const closeForm = useCallback((success: boolean) => {
        setOpenForm(false);
        setActiveAddButton(false);
        setFormErrors([]);
        if (success) {
            setOpenNotification(true);
        }
    }, []);

    const closeAddButton = useCallback(() => {
        setActiveAddButton(true);
    }, []);

    const openAddButton = useCallback(() => {
        setActiveAddButton(false);
    }, []);

    useEffect(() => {
        if (!!newMemberData || !isUpdate) {
            setFullName(`${newMemberData.firstName} ${newMemberData.lastName}`);
            refetch();
        }
        setUpdate(true);
    }, [refetch, newMemberData, isUpdate]);

    useEffect(() => {
        if (teamMembersFirstFetch) {
            setTeamMembersFirstFetch(firstLoading);
        }
    }, [firstLoading, teamMembersFirstFetch]);

    return (
        <div
            style={{ marginRight: '10rem', marginBottom: '10rem' }}
            data-testid="TeamMembersList_container"
        >
            <ButtonsContainer data-testid="TeamMembersList_buttonContainer">
                <div style={{ width: '100%' }}>
                    <div style={{ float: 'left', width: '18rem' }}>
                        <Dropdown
                            options={DropdownFilters}
                            onClick={() => console.log('Selected')}
                            placeholder="Select an Option"
                            disabled
                            smallDropdown
                        />
                    </div>
                    <div style={{ float: 'right', marginRight: '0%' }}>
                        <Button
                            margin={'0px'}
                            buttonColor={Colors.primary}
                            onClick={() => {
                                setOpenForm(true);
                                setActiveAddButton(true);
                            }}
                            disabled={activeAddButton}
                            buttonText="Add New Member"
                            size={Size.small}
                        />
                    </div>
                </div>
            </ButtonsContainer>
            <Notification
                notificationAppBar
                size={Size.small}
                isOpened={openNotification}
                closeIconProps={{ onClick: () => setOpenNotification(false), icon: 'faTimes' }}
                text={
                    <p style={{ marginRight: '2rem' }}>
                        You've added {fullName}. You'll now be tracking their status through Beacon!
                    </p>
                }
                leftIconProps={{
                    icon: 'faThumbsUp',
                    pro: true,
                }}
                notificationBg={Colors.success}
            />
            {openForm && (
                <div style={{ boxShadow: 'rgb(100, 100, 111, 0.2) 0px 2px 3px 0px' }}>
                    <NewTeamMemberForm
                        onSubmit={(d) => {
                            CreateTeamMember({
                                variables: {
                                    createTeamMemberInput: {
                                        workspaceId: workspaceId,
                                        teamId: teamId,
                                        firstName: d.firstName,
                                        lastName: d.lastName,
                                        zendeskEmail: d.zendeskEmail,
                                    },
                                },
                            });
                        }}
                        onCancel={closeForm}
                        callErrors={formErrors}
                        isLoading={loading}
                        teamName={teamName}
                    />
                </div>
            )}

            {teamMembersFirstFetch && (
                <div style={{ marginBottom: '1rem' }} data-testid="TeamMembersList_loaderIcon">
                    <div style={{ marginLeft: '50%', marginRight: '50%' }}>
                        <Icon
                            pro
                            className="fa-spin"
                            icon="faCircleNotch"
                            color={Colors.primary}
                            size={FontAwesomeSize.threeX}
                            margin="0px"
                        />
                    </div>
                </div>
            )}

            <TeamListForm
                teamList={teamMember}
                workspaceId={workspaceId}
                currentTeamId={teamId}
                teamName={teamName}
                onCancel={closeAddButton}
                onOpen={openAddButton}
                openForm={openForm}
            />
        </div>
    );
};

export default TeamMembersList;
