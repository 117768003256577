import { useQuery } from '@apollo/client';
import { useAppContext } from '../AppContext';
import {
    GetAllWorkspaceMembers,
    GetAllWorkspaceMembersVariables,
} from '../queries/types/GetAllWorkspaceMembers';
import { GET_ALL_WORKSPACE_MEMBERS } from '../queries/WorkspaceMembers';

export const useGetWorkspaceMembers = (
    variables: GetAllWorkspaceMembersVariables,
    onCompleted?: (data: GetAllWorkspaceMembers) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useQuery<GetAllWorkspaceMembers, GetAllWorkspaceMembersVariables>(
        GET_ALL_WORKSPACE_MEMBERS,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                    Authorization: `Bearer ${authToken || ''}`,
                },
            },
            variables,
            onCompleted,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            nextFetchPolicy: 'cache-first',
        }
    );
};
