import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider, currentWorkspace } from './AppContext';
import { auth } from './firebase';
import { Auth, FirebaseAuth } from './auth';
import FirebaseAnalytics from './FirebaseAnalytics';

import BaselineCSS from './components/BaselineCSS';
import Theme from './themes';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.npm_package_version,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV,
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
});

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            if (err?.message === 'Invalid session token!') {
                auth.signOut();
            }
        }
    }
});

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    currentWorkspace: {
                        read() {
                            return currentWorkspace();
                        },
                    },
                },
            },
        },
    }),
    defaultOptions: {
        query: {
            errorPolicy: 'all',
        },
    },
});

ReactDOM.render(
    <StrictMode>
        <AppContextProvider>
            <ApolloProvider client={client}>
                <Router>
                    <ThemeProvider theme={Theme}>
                        <BaselineCSS />
                        <FirebaseAuth>
                            <Auth>
                                <FirebaseAnalytics>
                                    <App />
                                </FirebaseAnalytics>
                            </Auth>
                        </FirebaseAuth>
                    </ThemeProvider>
                </Router>
            </ApolloProvider>
        </AppContextProvider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
