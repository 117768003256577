import { useLazyQuery } from '@apollo/client';

import { useAppContext } from '../AppContext';
import { GetExportDataVariables, GetExportData } from '../queries/types/GetExportData';
import { GET_EXPORT_DATA } from '../queries/ExportData';

export const useLazyGetExportData = () => {
    const { idToken, authToken } = useAppContext();
    return useLazyQuery<GetExportData, GetExportDataVariables>(GET_EXPORT_DATA, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });
};
