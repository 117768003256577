import {
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    setPersistence,
    browserSessionPersistence,
} from 'firebase/auth';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { auth } from '../firebase';
import { useAppContext } from '../AppContext';
import { LOGIN, LOGIN_TO_WORKSPACE } from '../queries/Auth';
import { Login, LoginVariables } from '../queries/types/Login';
import { LoginToWorkspace, LoginToWorkspaceVariables } from '../queries/types/LoginToWorkspace';

import {
    CreateWMemberAndUserFromInvite,
    CreateWMemberAndUserFromInviteVariables,
} from '../queries/types/CreateWMemberAndUserFromInvite';
import { CREATE_MEMBER_AND_USER_FROM_INVITE } from '../queries/Invitations';

export const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider().setCustomParameters({
        prompt: 'select_account',
    });

    return setPersistence(auth, browserSessionPersistence).then(() => {
        return signInWithPopup(auth, provider);
    });
};

export const logoutWithGoogle = () => {
    return signOut(auth);
};

export const useLazyLogin = () => {
    const { idToken } = useAppContext();
    return useLazyQuery<Login, LoginVariables>(LOGIN, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
            },
        },
        errorPolicy: 'ignore',
    });
};

export const useLoginToWorkspace = (
    variables: LoginToWorkspaceVariables,
    onCompleted: (data: LoginToWorkspace) => void,
    skip: boolean = true
) => {
    const { idToken, authToken } = useAppContext();

    return useQuery<LoginToWorkspace, LoginToWorkspaceVariables>(LOGIN_TO_WORKSPACE, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        variables,
        onCompleted,
        skip,
    });
};

export const useLazyAcceptInvite = () => {
    const { idToken } = useAppContext();
    return useMutation<CreateWMemberAndUserFromInvite, CreateWMemberAndUserFromInviteVariables>(
        CREATE_MEMBER_AND_USER_FROM_INVITE,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                },
            },
            onError() {
                //This is intentional
            },
        }
    );
};
