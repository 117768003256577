import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Colors } from '@partnerhero/new_design_system.utils.enums';
import { Input } from '@partnerhero/new_design_system.form_elements.input';

interface Props {
    onSubmit(data: IForm): void;
    callErrors?: string[];
    isLoading: boolean;
}

interface IForm {
    workspaceName: string;
}

const NewWorkspaceForm: FC<Props> = ({ onSubmit, callErrors, isLoading }) => {
    const {
        handleSubmit,
        formState: { errors, isDirty },
        setError,
        control,
    } = useForm<IForm>({
        mode: 'onSubmit',
    });

    useEffect(() => {
        callErrors?.forEach((message) =>
            setError('workspaceName', {
                type: 'manual',
                message: message,
            })
        );
    }, [callErrors, setError, isLoading]);

    return (
        <form
            data-testid={'form'}
            accept-charset="utf-8"
            onSubmit={handleSubmit((data) => onSubmit(data))}
        >
            <Grid container direction="column" wrap="wrap">
                <Controller
                    render={({ field: { onChange } }) => (
                        <Input
                            onChange={onChange}
                            placeholder="MyAwesomeWorkspace"
                            error={!!errors.workspaceName}
                            errorText={errors?.workspaceName?.message || ''}
                            label="Workspace Name"
                            subLabel="*Required"
                        />
                    )}
                    name="workspaceName"
                    rules={{
                        required: 'Workspace name is required',
                        pattern: {
                            value: /^(?=.*[^\W_])[\w' ]*$/,
                            message:
                                'Ooops...No special characters. Try using letters and/or numbers instead.',
                        },
                    }}
                    control={control}
                />
                <Button
                    fullWidth
                    margin="0px"
                    type="submit"
                    disabled={!!errors.workspaceName || isLoading || !isDirty}
                    buttonColor={Colors.success}
                    onClick={() => {
                        /** This is intentional. It is required by the DS */
                    }}
                    buttonText={
                        isLoading
                            ? 'Creating workspace...'
                            : 'I am happy with this name, let me in!'
                    }
                ></Button>
            </Grid>
        </form>
    );
};

export default NewWorkspaceForm;
