import { useEffect, ReactNode, useState } from 'react';
import { useHistory } from 'react-router';

import useFirebaseAuth from '../hooks/useFirebaseAuth';
import { useAppContext } from '../AppContext';
import AuthenticationDialog from '../components/AuthenticationDialog';
import ConnectionError from '../components/ConnectionError';
import { useLazyAcceptInvite, useLazyLogin } from '../services/Auth';
import { useQuery } from '../hooks/useQuery';

interface Props {
    children: ReactNode;
}

const Auth = ({ children }: Props) => {
    const [firebaseUser] = useFirebaseAuth();
    const { setUser, user, setAuthToken, idToken, setIdToken } = useAppContext();
    const [login, { data, loading, error }] = useLazyLogin();
    const history = useHistory();
    const query = useQuery();
    const [invitationId, setInvitationId] = useState<string | null>(query.get('i'));
    const [validationToken, setValidationToken] = useState<string | null>(query.get('t'));
    const [shouldWaitLogin, setShouldWaitLogin] = useState(true);

    const [register, { data: regData, loading: regLoading, error: regError, client }] =
        useLazyAcceptInvite();

    useEffect(() => {
        setInvitationId(query.get('i'));
        setValidationToken(query.get('t'));
    }, [query]);

    useEffect(() => {
        if (firebaseUser?.uid && idToken) {
            if (!regData && !regLoading && !regError) {
                if (invitationId && validationToken) {
                    register({
                        variables: {
                            input: {
                                invitationId,
                                userId: firebaseUser.uid,
                                validationToken,
                            },
                        },
                    });
                } else {
                    setShouldWaitLogin(false);
                }
            } else if (regData && !regLoading) {
                setShouldWaitLogin(false);
            }
        }
    }, [
        invitationId,
        validationToken,
        regData,
        regLoading,
        regError,
        firebaseUser?.uid,
        idToken,
        register,
        setShouldWaitLogin,
    ]);

    useEffect(() => {
        if (!shouldWaitLogin) {
            if (firebaseUser?.uid && idToken) {
                client.resetStore();
                login({
                    variables: {
                        uid: firebaseUser?.uid,
                    },
                });
            }
        }
    }, [shouldWaitLogin, firebaseUser?.uid, idToken, client, login]);

    useEffect(() => {
        if (firebaseUser && data && data.login.user && !user) {
            setAuthToken(data.login.token);
            setUser(data.login.user);
            const { workspaces } = data.login.user;

            const workspaceSlug = workspaces.length > 0 ? workspaces[0].slug : 'new';

            history.push(`/workspaces/${workspaceSlug}`);
        }
    }, [firebaseUser, user, loading, data, history, idToken, setAuthToken, setUser]);

    useEffect(() => {
        if (!firebaseUser && user) {
            setAuthToken('');
            setIdToken('');
            setUser(null);
        }
    }, [firebaseUser, setAuthToken, setUser, user, setIdToken]);

    if ((error && error.networkError) || (regError && regError.networkError)) {
        return <ConnectionError />;
    }

    return (firebaseUser && !user) || loading || regLoading || regError ? (
        <AuthenticationDialog loading={loading || regLoading} registrationError={!!regError} />
    ) : (
        <>{children}</>
    );
};

export default Auth;
