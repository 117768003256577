import { Switch, Route, useRouteMatch } from 'react-router';
import Teams from './TeamsListPage';
import TeamSettings from './Settings';

// Route: /workspaces/:slug/settings/teams
const Router = () => {
    const { url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${url}`} component={Teams} />
            <Route path={`${url}/:slug`} component={TeamSettings} />
        </Switch>
    );
};

export default Router;
