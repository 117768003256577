import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAppContext } from '../../../AppContext';

const WorkspaceRedirect: FC = () => {
    const { user } = useAppContext();
    const history = useHistory();

    useEffect(() => {
        if (user) {
            const { workspaces } = user;

            const workspaceSlug = workspaces.length > 0 ? workspaces[0].slug : 'new';

            history.push(`/workspaces/${workspaceSlug}`);
        }
    }, [history, user]);

    return null;
};

export default WorkspaceRedirect;
