import { FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Colors, FontAwesomeSize, Size } from '@partnerhero/new_design_system.utils.enums';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Notification } from '@partnerhero/new_design_system.notification.notification';

import TeamList from '../../../../components/TeamList';
import NewTeamForm from '../../../../components/NewTeamForm';

import { useAppContext } from '../../../../AppContext';
import { useCreateTeam, useGetTeamsByWorkspaceId } from '../../../../services/Team';
import { TeamsByWorkspaceId_getAllTeamsFromWorkspace } from '../../../../queries/types/TeamsByWorkspaceId';

const Panel = styled.div`
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 3px 0px;
`;

const TeamsListPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20rem;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

const TeamsListPage: FC = () => {
    const { workspaceId } = useAppContext();

    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [isFormOpen, setFormOpen] = useState(false);
    const [allTeams, setAllTeams] = useState<TeamsByWorkspaceId_getAllTeamsFromWorkspace[]>([]);
    const [openNotification, setOpenNotification] = useState<boolean>(false);

    const { loading: teamsLoading } = useGetTeamsByWorkspaceId(
        {
            workspaceId,
        },
        (data) => {
            setAllTeams(data.getAllTeamsFromWorkspace);
        }
    );

    const [createTeam, { loading }] = useCreateTeam(
        (data) => {
            setAllTeams((t) => [...t, data.createTeam]);
            setOpenNotification(true);
            closeForm();
        },
        (err) => {
            handleError(err?.graphQLErrors.map((e) => e.message));
        }
    );

    const closeForm = useCallback(() => {
        setFormOpen(false);
        setFormErrors([]);
    }, []);

    const handleError = useCallback((errors: string[]) => {
        setFormErrors(errors);
    }, []);

    const handleSubmit = useCallback(
        (teamName: string) => {
            createTeam({
                variables: {
                    input: {
                        workspaceId: workspaceId,
                        name: teamName,
                    },
                },
            });
        },
        [createTeam, workspaceId]
    );

    return (
        <TeamsListPageContainer>
            <Notification
                notificationAppBar
                margin="0px"
                size={Size.small}
                isOpened={openNotification}
                closeIconProps={{ onClick: () => setOpenNotification(false), icon: 'faTimes' }}
                text={<p>Your new team has been successfully created!</p>}
                leftIconProps={{
                    icon: 'faCheckDouble',
                    pro: true,
                }}
                notificationBg={Colors.success}
            />
            <Panel style={{ justifyContent: 'space-between', height: '78px', padding: '0 2rem' }}>
                <IconAndText>
                    <Icon
                        pro
                        icon={'faUsersCrown'}
                        color={Colors.primary}
                        size={FontAwesomeSize.twoX}
                        margin="0 0 0 2rem"
                    />
                    <h5 style={{ color: 'rgb(34, 53, 84)', margin: '0', marginLeft: '5rem' }}>
                        Teams
                    </h5>
                </IconAndText>
                {!isFormOpen && (
                    <Button
                        size={Size.small}
                        buttonColor={Colors.primary}
                        onClick={() => setFormOpen(true)}
                        type="button"
                        disabled={isFormOpen}
                        buttonText="Create Team"
                        margin="0"
                    />
                )}
            </Panel>
            {isFormOpen && (
                <Panel>
                    <NewTeamForm
                        onSubmit={(d) => {
                            handleSubmit(d.teamName);
                        }}
                        onCancel={closeForm}
                        callErrors={formErrors}
                        isLoading={loading}
                    />
                </Panel>
            )}
            <TeamList teams={allTeams} loading={teamsLoading} />
        </TeamsListPageContainer>
    );
};

export default TeamsListPage;
