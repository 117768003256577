import { FC } from 'react';
import AuthPage from '../AuthPage';
import LoadingDialog from '../Loader/LoadingDialog';

interface Props {
    loading: boolean;
    registrationError: boolean;
}
const AuthenticationDialog: FC<Props> = ({ loading, registrationError }) => {
    let failedMessage =
        'It doesn’t look like you’ve been invited to Beacon yet. Contact your manager for an invitation.';
    if (registrationError) {
        failedMessage =
            "Your invite link has expired or you were not the original recipient. Contact us in #proj-beacon-tool for an invite to set up a new workspace. If you'd like to join an existing workspace, contact your manager.";
    }

    return (
        <AuthPage>
            {loading ? (
                <LoadingDialog
                    title="Beacon calling Google…come in Google"
                    message="Please wait while we establish a connection..."
                    icon="faSatelliteDish"
                    showLoader
                />
            ) : (
                <LoadingDialog
                    title="Hmmm…Checked the list, you’re not on it."
                    message={failedMessage}
                    icon="faDoorClosed"
                    showLoader={false}
                    showRedirect
                />
            )}
        </AuthPage>
    );
};

export default AuthenticationDialog;
