import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useCreateWorkspace } from '../../../services/Workspace';
import NewWorkspaceForm from '../../../components/NewWorkspaceForm';
import LoadingDialog from '../../../components/Loader/LoadingDialog';
import AuthPageContainer from '../../../components/AuthPage';
import { useAppContext } from '../../../AppContext';

const CenterBlock = styled.div`
    padding: 3rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 3px 0px;
    align-items: center;
    margin: auto 6rem;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const CenterMessage = styled.div`
    flex-grow: 1;
    padding: 3rem;
    background-color: white;
    display: flex;
    align-items: center;
    margin: 20rem auto;
    @media (min-width: 768px) {
        flex-direction: row;
        z-index: 1;
    }
`;

const Footer = styled.p`
    color: #9090a9;
`;

const CenterBlockColumn = styled.div`
    width: 100%;
    @media (min-width: 768px) {
        padding: 2rem;
        width: 100%;
    }
`;

const LogoContainer = styled.div`
    @media (min-width: 768px) {
        align-self: flex-start;
    }
`;

const CreateWorkspacePage: FC = () => {
    const { setUser, user } = useAppContext();
    const history = useHistory();
    const [createWorkspace, { loading: isLoading, error: errors, data }] = useCreateWorkspace();

    useEffect(() => {
        if (!isLoading && !!data) {
            // fake the currently created workspace for the workspace auth to get the slug and id
            if (user?.workspaces.length === 0) {
                setUser({
                    ...user!,
                    workspaces: [
                        {
                            __typename: 'UserWorkspace',
                            displayName: '',
                            memberId: '',
                            slug: data.createWorkspace.slug,
                            workspaceId: data.createWorkspace.id,
                        },
                    ],
                });
            }
            setTimeout(() => {
                history.push(`/workspaces/${data.createWorkspace.slug}`);
            }, 5000);
        }
    }, [history, data, isLoading, user, setUser]);

    return (
        <AuthPageContainer centerComponent={!isLoading && !!data ? CenterMessage : CenterBlock}>
            {!isLoading && !!data && (
                <LoadingDialog
                    title="You’ve successfully created a workspace!"
                    message="Hold tight - we are now taking you to your settings to configure Zendesk and your teams."
                    icon="faDirections"
                    showLoader
                />
            )}
            {!data && (
                <>
                    <LogoContainer>
                        <img src="/assets/new-assets/Beacon-Logo.svg" alt="Beacon" />
                    </LogoContainer>
                    <CenterBlockColumn>
                        <h4>Nearly There...</h4>
                        <p className="body-medium">
                            To get started, you’ll need to create a Workspace.
                        </p>
                        <p className="body-medium">
                            Your Beacon Workspace is where your entire team will be able to easily
                            monitor individual and team productivity.
                        </p>
                        <Footer className="body-medium">
                            Pssst…Our friendly advice is to name your Workspace with the name of the
                            program, company, or organization you support.
                        </Footer>
                    </CenterBlockColumn>
                    <CenterBlockColumn>
                        <NewWorkspaceForm
                            onSubmit={(d) => {
                                createWorkspace({
                                    variables: {
                                        input: {
                                            name: d.workspaceName,
                                        },
                                    },
                                });
                            }}
                            callErrors={errors?.graphQLErrors?.map((e) => e.message)}
                            isLoading={isLoading}
                        />
                    </CenterBlockColumn>
                </>
            )}
        </AuthPageContainer>
    );
};

export default CreateWorkspacePage;
