import { FC } from 'react';
import { VerticalNavbar } from '@partnerhero/new_design_system.navigation.vertical_navbar';
import { VerticalNavLink } from '@partnerhero/new_design_system.navigation.vertical_nav_link';
import { Colors } from '@partnerhero/new_design_system.utils.enums';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { useAppContext } from '../../../AppContext';
import SidebarNavigationLinks from './SidebarNavigationLinks';
import useFirebaseAuth from '../../../hooks/useFirebaseAuth';
import { logoutWithGoogle } from '../../../services/Auth';

const Sidebar: FC = () => {
    const { setAppBar, user, selectedNavLink } = useAppContext();
    const { params } = useRouteMatch<{ slug: string }>();

    const [firebaseuser] = useFirebaseAuth();
    const history = useHistory();

    return (
        <VerticalNavbar
            highlightColor={Colors.primary}
            avatarProps={{ url: firebaseuser?.photoURL || '' }}
            username={firebaseuser ? `${firebaseuser.displayName}` : `${user?.email}`}
            highlightedLink={selectedNavLink}
            handleOnClickSettings={() => history.push(`/workspaces/${params.slug}/settings`)}
        >
            {SidebarNavigationLinks.map((item, index) => {
                const { id, title, icon } = item;
                const handleClick = () => {
                    if (id === 'logout') {
                        logoutWithGoogle();
                    } else {
                        history.push(`/workspaces/${params.slug}/${item.path}`);
                        setAppBar({
                            title,
                            icon,
                        });
                    }
                };
                return (
                    <VerticalNavLink
                        key={index}
                        onClick={handleClick}
                        iconLinkProps={{ icon, pro: true }}
                        text={title}
                    />
                );
            })}
        </VerticalNavbar>
    );
};

Sidebar.propTypes = {};

export default Sidebar;
