import { FC } from 'react';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Colors } from '@partnerhero/new_design_system.utils.enums';
import RequestFormCard from '../../../../components/RequestFormCard';

const RequestFormCardContainer: FC = () => {
    return (
        <>
            <Grid
                style={{
                    marginTop: '3.5rem',
                }}
            >
                <RequestFormCard
                    isErrorDialog={false}
                    title="Have Your Say"
                    description="We are looking for your feedback to make this screen better. Tell us what you would like to see!"
                    icon="faCommentAltEdit"
                    buttonColor={Colors.primary}
                    buttonText="Send Feedback"
                    href="https://forms.monday.com/forms/24193a9d3ef6e112dc5ba867eec5af04?r=use1"
                />
            </Grid>
            <Grid
                style={{
                    marginTop: '3.5rem',
                }}
            >
                <RequestFormCard
                    isErrorDialog={false}
                    title="Something Wrong?"
                    description="Have you noticed that something isn’t quite right? Submit a bug report and we'll investigate!"
                    icon="faBug"
                    buttonColor={Colors.error}
                    buttonText="Send Bug Report"
                    href="https://forms.monday.com/forms/cd5b4b14e654f11ae098494a221215ef?r=use1"
                />
            </Grid>
        </>
    );
};

export default RequestFormCardContainer;
