import { useQuery } from '@apollo/client';

import { useAppContext } from '../AppContext';
import {
    ProductivityCrunchVariables,
    ProductivityCrunch,
} from '../queries/types/ProductivityCrunch';
import { GetHourlyCrunchVariables, GetHourlyCrunch } from '../queries/types/GetHourlyCrunch';
import { PRODUCTIVITY_CRUNCH, GET_HOURLY_CRUNCH } from '../queries/Productivity';

export const useGetProductivityCrunch = (
    variables: ProductivityCrunchVariables,
    onCompleted: (data: ProductivityCrunch) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useQuery<ProductivityCrunch, ProductivityCrunchVariables>(PRODUCTIVITY_CRUNCH, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        variables,
        onCompleted,
        pollInterval: 300000, // 5 minutes
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });
};

export const useGetHourlyCrunch = (
    variables: GetHourlyCrunchVariables,
    onCompleted: (data: GetHourlyCrunch) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useQuery<GetHourlyCrunch, GetHourlyCrunchVariables>(GET_HOURLY_CRUNCH, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        variables,
        onCompleted,
        fetchPolicy: 'network-only',
    });
};
