import SidebarNavigationLinks from '../components/Layout/Sidebar/SidebarNavigationLinks';

interface NavigationLink {
    index: string;
    icon: string;
    title: string;
}

export const updateSelectedLink = (id: string) => {
    const links = SidebarNavigationLinks.filter((link) => link.id === id);
    if (links.length > 0) {
        return {
            index: SidebarNavigationLinks.indexOf(links[0]).toString(),
            icon: links[0].icon,
            title: links[0].title,
        } as NavigationLink;
    }
    return null;
};
