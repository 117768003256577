const SidebarNavigationLinks = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: 'faTachometerAltAverage',
        path: 'statistics',
    },
    {
        id: 'logout',
        title: 'Logout',
        icon: 'faSignOut',
        path: 'login',
    },
];

export default SidebarNavigationLinks;
