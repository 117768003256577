import { FC, useState } from 'react';
import { Table } from '@partnerhero/new_design_system.table.table';
import { Colors, FontAwesomeSize } from '@partnerhero/new_design_system.utils.enums';
import { Icon } from '@partnerhero/new_design_system.icons.icon';

import { useGetWorkspaceMembers } from '../../services/WorkspaceMember';
import { useAppContext } from '../../AppContext';

const columns = [{ title: 'First Name' }, { title: 'Last Name' }, { title: 'Email' }];

interface WorkspaceMember {
    name: string;
    email: string;
    id: string;
}
const WorkspaceMembersList: FC = () => {
    const { workspaceId } = useAppContext();
    const [workspaceMembers, setWorkspaceMembers] = useState<WorkspaceMember[]>([]);

    const { loading } = useGetWorkspaceMembers(
        {
            workspaceId: workspaceId,
        },
        (value) => {
            let workspaceMembersList = value?.getAllWorkspaceMembers?.map((wMember) => {
                const { name, email, id } = wMember;
                return {
                    name,
                    email,
                    id,
                };
            });
            setWorkspaceMembers(workspaceMembersList);
        }
    );

    return (
        <div>
            {loading && (
                <div style={{ marginBottom: '1rem' }}>
                    <div style={{ marginLeft: '50%', marginRight: '50%' }}>
                        <Icon
                            pro
                            className="fa-spin"
                            icon="faCircleNotch"
                            color={Colors.primary}
                            size={FontAwesomeSize.threeX}
                            margin="0px"
                        />
                    </div>
                </div>
            )}
            <Table
                data={{
                    columns,
                    rows: workspaceMembers.map((wMember) => {
                        const { name, email } = wMember;
                        const firstName = name.split(' ')[0];
                        const lastName = name.substring(firstName.length).trim();
                        return {
                            'First Name': firstName,
                            'Last Name': lastName,
                            Email: email,
                        };
                    }),
                }}
                rowOptions={{
                    editable: true,
                }}
                columnOptions={{ sortable: true }}
            />
        </div>
    );
};

export default WorkspaceMembersList;
