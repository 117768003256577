import { FC } from 'react';
import { AppBar } from '@partnerhero/new_design_system.navigation.appbar';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';

import { useAppContext } from '../../../AppContext';

const Topbar: FC = () => {
    const { appBar } = useAppContext();
    return (
        <AppBar
            containerProps={{
                backgroundColor: Colors.black,
                borderColor: Colors.black,
            }}
            leftIconProps={{
                pro: true,
                icon: appBar.icon,
                color: Colors.white,
                size: FontAwesomeSize.lg,
                margin: '0 10px 0 10px',
            }}
            leftText={appBar.title}
        />
    );
};
export default Topbar;
