import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { Colors, FontAwesomeSize } from '@partnerhero/new_design_system.utils.enums';
import RedirectCard from '../../../../components/RedirectCard';

const MainPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20rem;
`;

const Panel = styled.div`
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

const PolicyLinks: FC = () => {
    const termsAndServices = 'https://partnerhero.monday.com/docs/2287693621';
    const privacyPolicies = 'https://partnerhero.monday.com/docs/2287692192';
    return (
        <MainPageContainer data-testid="Policy_container">
            <Panel
                data-testid="Panel_container"
                style={{
                    justifyContent: 'space-between',
                    height: '8rem',
                    padding: '0 2rem',
                    boxShadow: 'rgb(100, 100, 111, 0.2) 0px 2px 3px 0px',
                }}
            >
                <IconAndText data-testid="iconAndText-container">
                    <Icon
                        pro
                        icon={'faBooks'}
                        color={Colors.primary}
                        size={FontAwesomeSize.twoX}
                        margin="0 0 0 2rem"
                    />
                    <h5 style={{ color: 'rgb(34, 53, 84)', margin: '0 0 0 5rem' }}>Policies</h5>
                </IconAndText>
            </Panel>

            <RedirectCard
                data-testid="Redirectcard_container1"
                icon={'faFile'}
                redirectUrl={termsAndServices}
                title={'Terms of Services'}
                subtitle={'View the legal agreement between You and Beacon.'}
            />

            <RedirectCard
                data-testid="Redirectcard_container2"
                icon={'faFile'}
                redirectUrl={privacyPolicies}
                title={'Privacy Policies'}
                subtitle={'Learn what data we collect, and how we use it.'}
            />
        </MainPageContainer>
    );
};

export default PolicyLinks;
