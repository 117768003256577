import { ReactNode } from 'react';

import BodyWrapper from '../../../../components/BodyWrapper';
import Sidebar from '../../../../components/Layout/Sidebar';
import Topbar from '../../../../components/Layout/Topbar';

interface Props {
    children: ReactNode;
}

const WorkspaceDashboard = ({ children }: Props) => {
    return (
        <div
            data-testid="WorkspaceDashboard-container"
            style={{ display: 'flex', minHeight: '100vh', maxWidth: '100vw', width: '100vw' }}
        >
            <Sidebar />
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    height: '100vh',
                    maxHeight: '100vh',
                    width: '100%',
                }}
            >
                <Topbar />
                <BodyWrapper>{children}</BodyWrapper>
            </div>
        </div>
    );
};

export default WorkspaceDashboard;
