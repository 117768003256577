import { gql } from '@apollo/client';

export const Team = gql`
    fragment Team on Team {
        id
        admins
        members
        name
        slug
        workspaceId
        slackChannel
    }
`;

export const TeamSlack = gql`
    fragment TeamSlack on TeamSlack {
        slackChannel
    }
`;

export const RenamedTeam = gql`
    fragment RenamedTeam on RenamedTeam {
        name
        slug
    }
`;

export const CREATE_TEAM = gql`
    mutation CreateTeam($input: CreateTeamInput!) {
        createTeam(input: $input) {
            ...Team
        }
    }
    ${Team}
`;

export const RENAME_TEAM = gql`
    mutation RenameTeam($input: RenameTeamInput!) {
        renameTeam(input: $input) {
            ...RenamedTeam
        }
    }
    ${RenamedTeam}
`;

export const RENAME_SLACK_CHANNEL = gql`
    mutation RenameSlackInput($input: SlackTeamInput!) {
        updateSlackChannel(input: $input) {
            ...TeamSlack
        }
    }
    ${TeamSlack}
`;

export const TEAMS_BY_WORKSPACEID = gql`
    query TeamsByWorkspaceId($workspaceId: String!) {
        getAllTeamsFromWorkspace(workspaceId: $workspaceId) {
            ...Team
        }
    }
    ${Team}
`;
