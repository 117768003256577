import { useMutation } from '@apollo/client';

import { useAppContext } from '../AppContext';
import { CreateWorkspace, CreateWorkspaceVariables } from '../queries/types/CreateWorkspace';
import { CREATE_WORKSPACE } from '../queries/Workspace';

export const useCreateWorkspace = () => {
    const { idToken, authToken } = useAppContext();
    return useMutation<CreateWorkspace, CreateWorkspaceVariables>(CREATE_WORKSPACE, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        onError: () => {
            // This is intentional
        },
    });
};
