import { FC, ReactNode, CSSProperties } from 'react';
import styled, { css } from 'styled-components';

const nonFluidStyles = css`
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1400px) {
        max-width: 1320px;
    }
`;

const StyledContainer = styled.div<StyledContainerProps>`
    width: 100%;
    padding: 20px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    ${(props) => !props.fluid && nonFluidStyles}
`;
interface StyledContainerProps {
    fluid?: boolean;
    style?: CSSProperties;
}

interface Props extends StyledContainerProps {
    children: ReactNode;
}

const Container: FC<Props> = ({ children, ...containerProps }) => {
    return <StyledContainer {...containerProps}>{children}</StyledContainer>;
};

export default Container;
