import styled from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Input } from '@partnerhero/new_design_system.form_elements.input';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Dropdown } from '@partnerhero/new_design_system.form_elements.dropdown';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors, Size } from '@partnerhero/new_design_system.utils.enums';
import { TeamsByWorkspaceId_getAllTeamsFromWorkspace } from '../../queries/types/TeamsByWorkspaceId';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 3px 0px;
`;

const Column = styled.div`
    flex-grow: 1;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface TeamMemberOption {
    'First Name': string;
    'Last Name': string;
    ZendeskEmail: string;
    teamId: string | undefined;
}

interface Props {
    onSubmit(data: IForm): void;
    callErrors: string[] | undefined;
    isLoading: boolean;
    teams: TeamsByWorkspaceId_getAllTeamsFromWorkspace[];
    teamMember: TeamMemberOption;
    teamName: string | undefined;
    onCancel?(): void;
    teamsLoading: boolean;
}

interface IForm {
    teamId: string | undefined;
    firstName: string;
    lastName: string;
    zendeskEmail: string;
    teamValue: string;
}

interface TeamsDropdown {
    value: string;
    text: string;
}

const EditTeamMemberForm: FC<Props> = ({
    onSubmit,
    callErrors,
    isLoading,
    teams,
    teamMember,
    teamName,
    onCancel = () => {
        // intended empty
    },
}) => {
    const {
        handleSubmit,
        formState: { errors },
        setError,
        control,
        reset,
    } = useForm<IForm>({
        mode: 'onSubmit',
    });
    const [firstName, setFirstName] = useState<string>(teamMember['First Name']);
    const [lastName, setLastName] = useState<string>(teamMember['Last Name']);
    const [newTeamId, setNewTeamId] = useState<string | undefined>();
    const [dropDownOptions, setDropdownOptions] = useState<TeamsDropdown[]>([
        { value: '', text: '' },
    ]);

    useEffect(() => {
        const updatedTeamsList = teams.map((team) => ({
            value: team.id,
            text: team.name,
        }));
        setDropdownOptions(updatedTeamsList);
    }, [teams]);

    useEffect(() => {
        callErrors?.forEach((message) =>
            setError('teamId', {
                type: 'validate',
                message: message,
            })
        );
    }, [callErrors, setError, isLoading]);

    return (
        <div data-testid="EditTeamMemberForm_container">
            <StyledContainer data-testid="EditTeamMemberForm_styledContainer">
                <Column
                    style={{ display: 'flex', alignItems: 'center', width: '50%' }}
                    data-testid="EditTeamMemberForm_leftColumn"
                >
                    <IconAndText data-testid="EditTeamMemberForm_iconAndTextContainer">
                        <Column>
                            <Icon
                                pro
                                icon="faUserEdit"
                                color={Colors.black}
                                size={FontAwesomeSize.twoX}
                                margin="0 0 0 4rem"
                            />
                        </Column>
                        <Column style={{ flexGrow: 2, margin: '0', marginLeft: '5rem' }}>
                            <h5 style={{ color: 'rgb(34, 53, 84)' }}>Edit Member</h5>
                            <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                                Edit details about this team member; Adjust their details or move
                                them to another team.
                            </p>
                        </Column>
                    </IconAndText>
                </Column>
                <Column data-testid="EditTeamMemberForm_rightColumn">
                    <div style={{ margin: '2rem 2rem 0 0' }}>
                        <form
                            accept-charset="utf-8"
                            data-testid="EditTeamMemberForm_form"
                            onSubmit={handleSubmit((data) => {
                                onSubmit({ ...data, teamId: newTeamId });
                            })}
                        >
                            <Grid container direction="column" wrap="wrap">
                                <Dropdown
                                    options={dropDownOptions}
                                    label="Team"
                                    subLabel="*required"
                                    onClick={(selectedId) => {
                                        setNewTeamId(selectedId.value);
                                    }}
                                    placeholder={teamName}
                                />

                                <Controller
                                    render={({ field: { onChange } }) => (
                                        <Input
                                            onChange={(e) => {
                                                const targetValue = e.target.value;
                                                setFirstName(targetValue);
                                                onChange(targetValue);
                                            }}
                                            placeholder={firstName}
                                            value={firstName}
                                            error={!!errors.firstName}
                                            errorText={errors?.firstName?.message || ''}
                                            label="First Name"
                                            subLabel="*required"
                                        />
                                    )}
                                    name="firstName"
                                    rules={{
                                        required: 'First name is required',
                                        pattern: {
                                            value: /^(?=.*[^\W_])[\w' ]*$/,
                                            message:
                                                'Ooops...No special characters. Try using letters and/or numbers instead.',
                                        },
                                    }}
                                    control={control}
                                />
                                <Controller
                                    render={({ field: { onChange } }) => (
                                        <Input
                                            onChange={(e) => {
                                                const targetValue = e.target.value;
                                                setLastName(targetValue);
                                                onChange(targetValue);
                                            }}
                                            placeholder={lastName}
                                            value={lastName}
                                            error={!!errors.lastName}
                                            errorText={errors?.lastName?.message || ''}
                                            label="Last Name"
                                            subLabel="*required"
                                        />
                                    )}
                                    name="lastName"
                                    rules={{
                                        required: 'Last name is required',
                                        pattern: {
                                            value: /^(?=.*[^\W_])[\w' ]*$/,
                                            message:
                                                'Ooops...No special characters. Try using letters and/or numbers instead.',
                                        },
                                    }}
                                    control={control}
                                />

                                <Input
                                    type="email"
                                    value={teamMember.ZendeskEmail}
                                    disabled
                                    onChange={() => {
                                        // intended empty
                                    }}
                                    placeholder="francis.drake@partnerhero.com"
                                    label="Zendesk Email"
                                    subLabel="*required"
                                />

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: '2rem',
                                    }}
                                >
                                    <div>
                                        <Button
                                            fullWidth
                                            margin={'0px'}
                                            buttonColor={Colors.grey}
                                            onClick={() => {
                                                onCancel();
                                            }}
                                            buttonText="Cancel"
                                            type="button"
                                            size={Size.small}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            fullWidth
                                            margin={'0px'}
                                            buttonColor={Colors.success}
                                            onClick={() => {
                                                reset(
                                                    {
                                                        firstName: firstName,
                                                        lastName: lastName,
                                                    },
                                                    {
                                                        keepErrors: false,
                                                        keepDirty: true,
                                                        keepIsSubmitted: false,
                                                        keepTouched: false,
                                                        keepIsValid: false,
                                                        keepSubmitCount: false,
                                                    }
                                                );
                                            }}
                                            type="submit"
                                            buttonText={
                                                isLoading
                                                    ? 'Creating team member...'
                                                    : 'Save Changes'
                                            }
                                            size={Size.small}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </form>
                    </div>
                </Column>
            </StyledContainer>
        </div>
    );
};

export default EditTeamMemberForm;
