import { gql } from '@apollo/client';

export const User = gql`
    fragment User on User {
        id
        email
        createdAt
        updatedAt
        workspaces {
            displayName
            workspaceId
            slug
            memberId
        }
    }
`;
