import { gql } from '@apollo/client';

export const TeamMember = gql`
    fragment TeamMember on TeamMember {
        id
        slug
        firstName
        lastName
        zendeskEmail
        workspaceId
        teamId
    }
`;

export const CREATE_TEAM_MEMBERS = gql`
    mutation CreateTeamMember($createTeamMemberInput: CreateTeamMemberInput!) {
        createTeamMember(createTeamMemberInput: $createTeamMemberInput) {
            ...TeamMember
        }
    }
    ${TeamMember}
`;

export const GET_TEAM_MEMBERS_FROM_TEAM = gql`
    query GetTeamMembersFromTeam($workspaceId: String!, $teamId: String!) {
        getTeamMembersFromTeam(workspaceId: $workspaceId, teamId: $teamId) {
            ...TeamMember
        }
    }
    ${TeamMember}
`;

export const CHANGE_TEAM_MEMBER_FROM_TEAM = gql`
    mutation ChangeTeamMemberFromTeam($input: ChangeTeamInput!) {
        changeTeamMemberFromTeam(input: $input) {
            ...TeamMember
        }
    }
    ${TeamMember}
`;

export const EDIT_TEAM_MEMBER = gql`
    mutation EditTeamMember($editTeamMemberInput: EditTeamMemberInput!) {
        editTeamMember(editTeamMemberInput: $editTeamMemberInput) {
            ...TeamMember
        }
    }
    ${TeamMember}
`;
