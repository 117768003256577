import { gql } from '@apollo/client';

export const ExportWorkspaceData = gql`
    fragment ExportWorkspaceData on ExportWorkspaceData {
        workspaceName
        teamsData {
            teamName
            teamMembersData {
                teamMemberName
                ticketsData {
                    ticketId
                    status
                    lastUpdated
                }
            }
        }
    }
`;

export const GET_EXPORT_DATA = gql`
    query GetExportData(
        $workspaceId: String!
        $teamId: String
        $teamMemberId: String
        $beginTime: Float!
        $endTime: Float!
    ) {
        getExportData(
            workspaceId: $workspaceId
            teamId: $teamId
            teamMemberId: $teamMemberId
            beginTime: $beginTime
            endTime: $endTime
        ) {
            ...ExportWorkspaceData
        }
    }
    ${ExportWorkspaceData}
`;
