import { FC } from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    display: flex;
    height: 100%;
`;

const ImageContainer = styled.div`
    margin: auto;
`;

const Image = styled.img`
    width: 200px;
    height: 200px;
`;

const Loader: FC = () => {
    return (
        <LoaderContainer data-testid="Loader_container">
            <ImageContainer data-testid="Loader_ImageContainer">
                <Image
                    style={{}}
                    src="/assets/new-assets/Beacon-Logo.svg"
                    alt="Loading..."
                    data-testid="Loader_Image"
                />
                <h4
                    style={{
                        color: '#223554',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: '0px',
                        marginLeft: '15%',
                    }}
                    className="bold-large"
                >
                    Loading...
                </h4>
            </ImageContainer>
        </LoaderContainer>
    );
};

export default Loader;
