import { DefaultTheme } from 'styled-components';
import theme from '@partnerhero/new_design_system.utils.theme';

const defaultTheme: DefaultTheme = {
    mode: 'light',
    colors: {
        primary: theme.colors.primary,
        secondary: theme.colors.secondary,
        black: theme.colors.black,
        grey: theme.colors.grey,
        white: theme.colors.white,
        success: theme.colors.success,
        warning: theme.colors.warning,
        error: theme.colors.error,
        containerBackground: 'rgb(236, 243, 254)',
    },
};

export default defaultTheme;
