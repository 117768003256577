import styled from 'styled-components';
import { FC } from 'react';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';

const FormLoaderContainer = styled.div`
    height: 27rem;
    widht: 100%;
    margin-bottom: 3rem;
`;

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DashboardLoader: FC = () => {
    return (
        <FormLoaderContainer>
            <CenterContainer>
                <div style={{ marginTop: '5rem' }}>
                    <Icon
                        pro
                        className="fa-spin"
                        icon="faCircleNotch"
                        color={Colors.primary}
                        size={FontAwesomeSize.threeX}
                        margin="0"
                    />
                </div>
                <div>
                    <h5>Hold Tight!</h5>
                </div>
                <div>
                    <p style={{ fontSize: '1.3rem' }}>
                        We are just grabbing your data, should be done in few seconds!
                    </p>
                </div>
            </CenterContainer>
        </FormLoaderContainer>
    );
};

export default DashboardLoader;
