import { gql } from '@apollo/client';
import { User } from './Users';

export const Invitation = gql`
    fragment Invitation on Invitation {
        email
    }
`;

export const CREATE_WORKSPACE_INVITATION = gql`
    mutation CreateWorkspaceInvitation($input: WorkspaceInvitationInput!) {
        createWorkspaceInvitation(input: $input) {
            ...Invitation
        }
    }
    ${Invitation}
`;

export const GET_ACTIVE_INVITATIONS_FOR_WORKSPACE = gql`
    query GetActiveInvitationsForWorkspace($workspaceId: String!) {
        getActiveInvitationsForWorkspace(workspaceId: $workspaceId) {
            ...Invitation
        }
    }
    ${Invitation}
`;

export const CREATE_MEMBER_AND_USER_FROM_INVITE = gql`
    mutation CreateWMemberAndUserFromInvite($input: CreateFromInviteInput!) {
        createWMemberAndUserFromInvite(input: $input) {
            ...User
        }
    }
    ${User}
`;
