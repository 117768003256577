import { FC } from 'react';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { InfoCard } from '@partnerhero/new_design_system.card.info';
import { Colors } from '@partnerhero/new_design_system.utils.enums';

interface Card {
    id: string;
    title: string;
    amount: number;
}

interface Props {
    cards: Card[] | null;
    loading: boolean;
}

const MetricCards: FC<Props> = ({ cards, loading }) => {
    return (
        <>
            {!loading && (
                <Grid data-testid="MetricsCard_container" container direction="row" wrap="wrap">
                    {cards?.map((card) => (
                        <Grid
                            data-testid="MetricsCard_title"
                            item
                            style={{
                                width: '24%',
                                marginTop: '1.563rem',
                                marginLeft: '0.750rem',
                            }}
                            key={card.title.replace(/\s/g, '')}
                        >
                            <InfoCard
                                data-testid="MetricsCard_data"
                                title={card.title}
                                titleColor={Colors.primary}
                                number={card.amount}
                                containerProps={{ shadow: true }}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export default MetricCards;
