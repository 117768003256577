import { useEffect, ReactNode, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { getAnalytics, logEvent, setCurrentScreen, setUserId } from 'firebase/analytics';
import { useAppContext } from './AppContext';

interface HistoryState {
    from: string;
    fromSearch: string;
}

interface Props {
    children: ReactNode;
}

const FirebaseAnalytics: FC<Props> = ({ children }: Props) => {
    const firebaseAnalytics = getAnalytics();
    const location = useLocation<HistoryState>();
    const { user } = useAppContext();

    useEffect(() => {
        if (firebaseAnalytics) {
            if (user) {
                setUserId(firebaseAnalytics, user.id);
            }
            const currentPath = location.pathname + location.search;
            setCurrentScreen(firebaseAnalytics, currentPath);
            logEvent(firebaseAnalytics, 'page_visited', { currentPath });
        }
    }, [firebaseAnalytics, location, user]);
    return <>{children}</>;
};

export default FirebaseAnalytics;
