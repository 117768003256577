import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';
import { bodyMedium, boldMedium } from '@partnerhero/new_design_system.utils.typography';

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 100%;
`;

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 440px;
    height: 198px;
`;

const ErrorDescription = styled.p`
    ${bodyMedium}
    color: rgb(34, 53, 84);
    width: 440px;
    text-align: center;
`;

const SlackChannelDescription = styled.p`
    ${boldMedium}
    color: rgb(24, 107, 237);
    width: 440px;
    text-align: center;
`;

const ConnectionError: FC = () => {
    return (
        <MainContainer data-testid="ConnectionError_test">
            <CenterContainer data-testid="CenterContainer_test">
                <div>
                    <Icon
                        pro
                        icon="faExclamationTriangle"
                        color={Colors.primary}
                        size={FontAwesomeSize.twoX}
                        margin="0px"
                    />
                </div>
                <div>
                    <h5 style={{ color: 'rgb(34, 53, 84)' }}>
                        Ohhh…We are unable to connect to Beacon
                    </h5>
                </div>
                <div>
                    <ErrorDescription>
                        There seems to be a problem with Beacon at the moment. Don’t worry we are on
                        it! If you continue to have problems you can reach out to our support.
                    </ErrorDescription>
                </div>
                <div>
                    <a
                        href="https://partnerhero.slack.com/archives/CGJE0557V"
                        role="button"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <SlackChannelDescription>
                            #proj-beacon-tool Slack Channel
                        </SlackChannelDescription>
                    </a>
                </div>
            </CenterContainer>
        </MainContainer>
    );
};

export default ConnectionError;
