import { FC } from 'react';
import styled from 'styled-components';
import { Table } from '@partnerhero/new_design_system.table.table';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';

const Panel = styled.div`
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface InvitationOption {
    Email: string;
}

interface Props {
    invitationsList: InvitationOption[] | undefined;
}

const InvitationsList: FC<Props> = ({ invitationsList }) => {
    return (
        <>
            <Panel style={{ justifyContent: 'space-between', height: '8rem', padding: '0 2rem' }}>
                <IconAndText>
                    <Icon
                        pro
                        icon={'faPaperPlane'}
                        color={Colors.primary}
                        size={FontAwesomeSize.twoX}
                        margin="0 0 0 2rem"
                    />
                    <h5 style={{ color: 'rgb(34, 53, 84)', margin: '0 0 0 5rem' }}>
                        Invited Members
                    </h5>
                </IconAndText>
            </Panel>

            <Table
                margin="0 0 10rem"
                data={{
                    columns: [{ title: 'Email', options: { justify: 'center' } }],
                    rows: invitationsList,
                }}
            />
        </>
    );
};

export default InvitationsList;
