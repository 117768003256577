import { Redirect, Switch, Route } from 'react-router-dom';

import Login from './containers/Login';
import { Router as WorkspacesRouter } from './containers/Workspaces';
import NotFound from './components/NotFoundDialog';

const App = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/workspaces" />
            </Route>
            <Route path="/workspaces" component={WorkspacesRouter} />
            <Route path="/login" component={Login} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default App;
