import { Switch, Route, useRouteMatch, Redirect } from 'react-router';
import WorkspaceDashboard from './WorkspaceDashboard';
import Settings from '../Settings/Router';
import Statistics from '../Statistics';

// Route: /workspaces/:slug
const Router = () => {
    const { url } = useRouteMatch();

    return (
        <WorkspaceDashboard>
            <Switch>
                <Route exact path={url}>
                    <Redirect to={`${url}/statistics`} />
                </Route>
                <Route path={`${url}/statistics`} component={Statistics} />
                <Route path={`${url}/settings`} component={Settings} />
            </Switch>
        </WorkspaceDashboard>
    );
};

export default Router;
