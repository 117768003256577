import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { useAppContext } from '../AppContext';
import { CreateTeamMember, CreateTeamMemberVariables } from '../queries/types/CreateTeamMember';
import {
    ChangeTeamMemberFromTeam,
    ChangeTeamMemberFromTeamVariables,
} from '../queries/types/ChangeTeamMemberFromTeam';
import { EditTeamMember, EditTeamMemberVariables } from '../queries/types/EditTeamMember';
import {
    GetTeamMembersFromTeam,
    GetTeamMembersFromTeamVariables,
} from '../queries/types/GetTeamMembersFromTeam';
import {
    CREATE_TEAM_MEMBERS,
    GET_TEAM_MEMBERS_FROM_TEAM,
    CHANGE_TEAM_MEMBER_FROM_TEAM,
    EDIT_TEAM_MEMBER,
} from '../queries/TeamMembers';

export const useCreateTeamMember = (
    onCompleted?: (data: CreateTeamMember) => void,
    onError?: (error: ApolloError) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useMutation<CreateTeamMember, CreateTeamMemberVariables>(CREATE_TEAM_MEMBERS, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        onCompleted,
        onError,
    });
};
export const useGetTeamMembers = (
    variables: GetTeamMembersFromTeamVariables,
    onCompleted: (data: GetTeamMembersFromTeam) => void
) => {
    const { idToken, authToken } = useAppContext();
    return useQuery<GetTeamMembersFromTeam, GetTeamMembersFromTeamVariables>(
        GET_TEAM_MEMBERS_FROM_TEAM,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                    Authorization: `Bearer ${authToken || ''}`,
                },
            },
            variables,
            onCompleted,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        }
    );
};

export const useLazyTeamMembersFetch = () => {
    const { idToken, authToken } = useAppContext();
    return useLazyQuery<GetTeamMembersFromTeam, GetTeamMembersFromTeamVariables>(
        GET_TEAM_MEMBERS_FROM_TEAM,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                    Authorization: `Bearer ${authToken || ''}`,
                },
            },
            fetchPolicy: 'network-only',
        }
    );
};

export const useChangeTeamMemberFromTeam = () => {
    const { idToken, authToken } = useAppContext();
    return useMutation<ChangeTeamMemberFromTeam, ChangeTeamMemberFromTeamVariables>(
        CHANGE_TEAM_MEMBER_FROM_TEAM,
        {
            context: {
                headers: {
                    'x-firebase-id-token': idToken || '',
                    Authorization: `Bearer ${authToken || ''}`,
                },
            },
            onError: () => {
                // This is intentional
            },
            refetchQueries: [GET_TEAM_MEMBERS_FROM_TEAM, 'GetTeamMembersFromTeam'],
        }
    );
};

export const useUpdateTeamMember = () => {
    const { idToken, authToken } = useAppContext();
    return useMutation<EditTeamMember, EditTeamMemberVariables>(EDIT_TEAM_MEMBER, {
        context: {
            headers: {
                'x-firebase-id-token': idToken || '',
                Authorization: `Bearer ${authToken || ''}`,
            },
        },
        onError: () => {
            // This is intentional
        },
        refetchQueries: [GET_TEAM_MEMBERS_FROM_TEAM, 'GetTeamMembersFromTeam'],
    });
};
