import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import Container from '../Container';

const BodyWrapperMain = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
interface Props {
    children: ReactNode;
}

const BodyWrapper: FC<Props> = ({ children }) => {
    return (
        <BodyWrapperMain>
            <Container fluid style={{ height: '100%' }}>
                {children}
            </Container>
        </BodyWrapperMain>
    );
};

export default BodyWrapper;
