import { useHistory, useRouteMatch } from 'react-router';
import { FC } from 'react';
import { Table } from '@partnerhero/new_design_system.table.table';
import { TeamsByWorkspaceId_getAllTeamsFromWorkspace } from '../../queries/types/TeamsByWorkspaceId';
import { Colors, FontAwesomeSize } from '@partnerhero/new_design_system.utils.enums';
import { Icon } from '@partnerhero/new_design_system.icons.icon';

const columns = [
    { title: 'Team Name' },
    { title: 'Members' },
    { title: 'Admins' },
    { title: 'Slack Channel' },
];
export interface TeamOption {
    'Team Name': string;
    Members: number;
    Admins: number;
    slug: string;
    teamId: string;
    'Slack Channel': string;
}

interface Props {
    teams: TeamsByWorkspaceId_getAllTeamsFromWorkspace[];
    loading?: boolean;
}

const TeamList: FC<Props> = ({ teams, loading = false }) => {
    const history = useHistory();
    const { url } = useRouteMatch();

    return (
        <div data-testid="TeamList_container">
            {loading && (
                <div style={{ marginBottom: '1rem' }} data-testid="TeamList_image_container">
                    <div style={{ marginLeft: '50%', marginRight: '50%' }}>
                        <Icon
                            pro
                            className="fa-spin"
                            icon="faCircleNotch"
                            color={Colors.primary}
                            size={FontAwesomeSize.threeX}
                            margin="0px"
                        />
                    </div>
                </div>
            )}
            <Table
                data-testid="TeamList_Table"
                data={{
                    columns,
                    rows: teams.map((team) => ({
                        'Team Name': team.name,
                        Members: team.members,
                        Admins: team.admins,
                        slug: team.slug,
                        teamId: team.id,
                        'Slack Channel': team.slackChannel,
                    })),
                }}
                rowOptions={{
                    editable: true,
                }}
                onEdit={(objectBeingEdited: TeamOption) => {
                    history.push({
                        pathname: `${url}/${objectBeingEdited.slug}`,
                        state: {
                            teamName: objectBeingEdited['Team Name'],
                            teamId: objectBeingEdited.teamId,
                            slackChannel: objectBeingEdited['Slack Channel'],
                        },
                    });
                }}
                columnOptions={{ sortable: true }}
            />
        </div>
    );
};

export default TeamList;
