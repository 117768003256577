import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab } from '@partnerhero/new_design_system.navigation.tab';
import { Tabs } from '@partnerhero/new_design_system.navigation.tabs';
import { Alignment } from '@partnerhero/new_design_system.utils.enums';
import { useHistory, useLocation } from 'react-router';
import { bodySmall } from '@partnerhero/new_design_system.utils.typography';

import { useAppContext } from '../../../../AppContext';

const WorkspaceId = styled.p`
    ${bodySmall}
    width: 250px;
    text-align: left;
    margin-top: 0px;
    color: rgb(144, 154, 169);
    font-family: Inter-SemiBold;
    font-size: 10px;
    font-weight: 600;
    height: 12px;
    letter-spacing: 0px;
    margin-left: 2rem;
`;

interface Props {
    children: ReactNode;
}
interface HistoryState {
    from: string;
    fromSearch: string;
}

interface TabsStatus {
    teamTab: boolean;
    memberTab: boolean;
    policiesTab: boolean;
}

const SettingsPage: FC<Props> = ({ children }) => {
    const history = useHistory<HistoryState>();
    const location = useLocation<HistoryState>();
    const { pathname } = location;
    const loadedTab = pathname.split('/')[4];
    const { appBar, setAppBar, setSelectedNavLink, workspaceSlug, workspaceId } = useAppContext();

    useEffect(() => {
        if (appBar.title !== 'Settings') {
            setAppBar({ icon: 'faCog', title: 'Settings' });
            setSelectedNavLink('settings');
        }
    }, [appBar, setAppBar, setSelectedNavLink]);

    const [tabsStatus, setTabsStatus] = useState<TabsStatus>({
        teamTab: false,
        memberTab: false,
        policiesTab: false,
    });

    useEffect(() => {
        if (loadedTab === 'teams') {
            setTabsStatus({
                teamTab: true,
                memberTab: false,
                policiesTab: false,
            });
        } else if (loadedTab === 'workspace-members') {
            setTabsStatus({
                teamTab: false,
                memberTab: true,
                policiesTab: false,
            });
        } else if (loadedTab === 'policies') {
            setTabsStatus({
                teamTab: false,
                memberTab: false,
                policiesTab: true,
            });
        }
    }, [loadedTab]);

    const tabsList = [
        {
            value: 'teams',
            text: 'Teams',
            onClick: () => {
                history.push({
                    pathname: `/workspaces/${workspaceSlug}/settings/teams`,
                });
                setTabsStatus({
                    teamTab: true,
                    memberTab: false,
                    policiesTab: false,
                });
            },
            active: tabsStatus['teamTab'],
        },
        {
            value: 'workspace-members',
            text: 'Workspace Members',
            onClick: () => {
                history.push({
                    pathname: `/workspaces/${workspaceSlug}/settings/workspace-members`,
                });
                setTabsStatus({
                    teamTab: false,
                    memberTab: true,
                    policiesTab: false,
                });
            },
            active: tabsStatus['memberTab'],
        },
        {
            value: 'policies',
            text: 'Policies',
            onClick: () => {
                history.push({
                    pathname: `/workspaces/${workspaceSlug}/settings/policies`,
                });
                setTabsStatus({
                    teamTab: false,
                    memberTab: false,
                    policiesTab: true,
                });
            },
            active: tabsStatus['policiesTab'],
        },
    ];
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ marginTop: '150px' }}>
                <Tabs selectedOption="teams" displayAsColumn alignTabs={Alignment.left}>
                    {tabsList.map(({ value, text, onClick, active }, index) => (
                        <div style={{ width: '300px', height: '60px' }} key={index}>
                            <Tab
                                key={index}
                                onClick={onClick}
                                value={value}
                                text={text}
                                active={active}
                            />
                        </div>
                    ))}
                </Tabs>
                <WorkspaceId>Workspace ID: {workspaceId}</WorkspaceId>
            </div>
            <div style={{ flexGrow: 2 }}>{children}</div>
        </div>
    );
};

export default SettingsPage;
