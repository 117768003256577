import { FC } from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import styled from 'styled-components';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { bodyMedium, header4Style } from '@partnerhero/new_design_system.utils.typography';
import { Colors, Size } from '@partnerhero/new_design_system.utils.enums';

export const RequestFormCardTitle = styled.h5`
    ${header4Style}
    text-align: center;
    color: rgb(34, 53, 84);
    font-size: 1.8rem;
    margin-top: 15px;
`;

export const RequestFormCardDescription = styled.p<DescriptionProps>`
    ${bodyMedium}
    text-align: center;
    color: rgb(34, 53, 84);
    font-family: Inter-Regular;
    font-size: ${(props) => (props.isErrorDialog ? '14px' : '12px')};
    line-height: 20px;
    margin-top: 0px;
    width: 100%;
    @media (min-width: 768px) {
        width: ${(props) => (props.isErrorDialog ? '440px' : '324px')};
    }
`;
interface DescriptionProps {
    isErrorDialog: boolean;
}
interface RequestFormCardProps {
    icon?: string;
    title?: string;
    description?: string;
    buttonColor?:
        | Colors.primary
        | Colors.secondary
        | Colors.success
        | Colors.warning
        | Colors.error
        | Colors.neutral
        | Colors.white;
    iconColor?:
        | Colors.primary
        | Colors.secondary
        | Colors.success
        | Colors.warning
        | Colors.error
        | Colors.neutral
        | Colors.white
        | Colors.darkGrey;
    buttonText?: string;
    isErrorDialog?: boolean;
    href?: string;
}

const RequestFormCard: FC<RequestFormCardProps> = (props) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { icon, title, description, buttonColor, iconColor, buttonText, isErrorDialog, href } =
        props;

    return (
        <Grid
            data-testid="RequestFormCard_container"
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
        >
            <Icon data-testid="RequestFormCard_icon" brand pro icon={icon!} color={iconColor} />
            <RequestFormCardTitle data-testid="RequestFormCard_Title">
                {title!}
            </RequestFormCardTitle>
            <RequestFormCardDescription
                data-testid="RequestFormCard_description"
                isErrorDialog={isErrorDialog!}
            >
                {description!}
            </RequestFormCardDescription>
            <Button
                data-testid="RequestFormCard_Button"
                buttonText={buttonText}
                onClick={() => {
                    if (isErrorDialog) {
                        const newUrl = url.slice(0, url.lastIndexOf('/'));
                        history.push(`${newUrl}/settings`);
                    } else {
                        window.open(href);
                    }
                }}
                fontColor={Colors.white}
                buttonColor={buttonColor}
                size={Size.small}
                type="button"
            />
        </Grid>
    );
};

RequestFormCard.defaultProps = {
    icon: undefined,
    title: undefined,
    description: undefined,
    iconColor: Colors.darkGrey,
    buttonColor: Colors.primary,
    buttonText: undefined,
    isErrorDialog: false,
    href: undefined,
};

export default RequestFormCard;
