import styled from 'styled-components';
import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Input } from '@partnerhero/new_design_system.form_elements.input';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors, Size } from '@partnerhero/new_design_system.utils.enums';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;

const Column = styled.div`
    flex-grow: 1;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    onSubmit(data: IForm): void;
    callErrors: string[] | undefined;
    isLoading: boolean;
    onCancel?(): void;
}

interface IForm {
    invitationEmail: string;
}

const InviteWorkspaceMemberForm: FC<Props> = ({
    onSubmit,
    callErrors,
    isLoading,
    onCancel = () => {
        // intended empty
    },
}) => {
    const {
        handleSubmit,
        formState: { errors, isDirty },
        setError,
        control,
        reset,
        clearErrors,
    } = useForm<IForm>({
        mode: 'onSubmit',
    });

    useEffect(() => {
        callErrors?.forEach((message) =>
            setError('invitationEmail', {
                type: 'validate',
                message: message,
            })
        );
    }, [callErrors, setError, isLoading]);

    return (
        <StyledContainer data-testid="InviteWorkspaceMemberForm_StyledContainer">
            <Column style={{ display: 'flex', alignItems: 'center' }}>
                <IconAndText>
                    <Column>
                        <Icon
                            pro
                            icon="faPaperPlane"
                            color={Colors.black}
                            size={FontAwesomeSize.twoX}
                            margin="0 0 0 2rem"
                        />
                    </Column>
                    <Column
                        style={{ flexGrow: 2, margin: '0', marginLeft: '5rem', width: '324px' }}
                    >
                        <h5 style={{ color: 'rgb(34, 53, 84)' }}>Invite Workspace Member</h5>
                        <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                            Enter the email address of the person you wish to invite to the
                            workspace.
                        </p>
                    </Column>
                </IconAndText>
            </Column>
            <Column>
                <form
                    data-testid="form"
                    accept-charset="utf-8"
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                >
                    <Grid container direction="column" wrap="wrap">
                        <Controller
                            render={({ field: { onChange } }) => (
                                <Input
                                    type="email"
                                    data-testid="input"
                                    onChange={onChange}
                                    placeholder="francis.drake@partnerhero.com"
                                    error={!!errors.invitationEmail}
                                    errorText={errors?.invitationEmail?.message || ''}
                                    label="Email Address"
                                    subLabel="* Must be an @partnerhero.com address"
                                />
                            )}
                            name="invitationEmail"
                            rules={{
                                required: !!callErrors
                                    ? callErrors[0]
                                    : '@partnerhero.com email is required',
                            }}
                            control={control}
                        />

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <Button
                                    fullWidth
                                    margin="0px"
                                    buttonColor={Colors.grey}
                                    onClick={() => {
                                        reset(
                                            { invitationEmail: '' },
                                            {
                                                keepErrors: false,
                                                keepDirty: false,
                                                keepIsSubmitted: false,
                                                keepIsValid: false,
                                                keepTouched: false,
                                            }
                                        );
                                        clearErrors();
                                        onCancel();
                                    }}
                                    buttonText="Cancel"
                                    type="button"
                                    size={Size.small}
                                />
                            </div>
                            <div>
                                <Button
                                    fullWidth
                                    margin={'0px'}
                                    disabled={!!errors.invitationEmail || isLoading || !isDirty}
                                    buttonColor={Colors.success}
                                    onClick={() => {
                                        // intended empty
                                    }}
                                    type="submit"
                                    buttonText={isLoading ? 'Sending Invite...' : 'Send Invite'}
                                    size={Size.small}
                                />
                            </div>
                        </div>
                    </Grid>
                </form>
            </Column>
        </StyledContainer>
    );
};

export default InviteWorkspaceMemberForm;
