import styled from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Input } from '@partnerhero/new_design_system.form_elements.input';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { Notification } from '@partnerhero/new_design_system.notification.notification';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { FontAwesomeSize, Colors, Size } from '@partnerhero/new_design_system.utils.enums';
import { useAppContext } from '../../AppContext';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 20px;
    margin-right: 10rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 3px 0px;
`;

const Column = styled.div`
    flex-grow: 1;
    background-color: white;
`;

const ColumnInput = styled.div`
    flex-grow: 1;
    background-color: white;
    width: 40%;
`;

const ColumnText = styled.div`
    flex-grow: 1;
    background-color: white;
    width: 38rem;
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    onSubmit(data: IForm): void;
    callErrors: string[] | undefined;
    isLoading: boolean;
    teamName: string;
    slackChannel: string;
}

interface IForm {
    teamName: string;
    slackChannel: string;
}

const EditTeamForm: FC<Props> = ({ onSubmit, callErrors, isLoading, teamName, slackChannel }) => {
    const { workspaceSlug } = useAppContext();
    const [openNotification, setOpenNotification] = useState(false);
    const [inputTeamNameValue, setInputTeamNameValue] = useState<string>(teamName);
    const [inputSlackChannelValue, setInputSlackChannelValue] = useState<string>(slackChannel);
    const [teamNameIsEqual, setTeamNameIsEqual] = useState<boolean>(true);
    const [slackIsEqual, setSlackIsEqual] = useState<boolean>(true);
    const history = useHistory();
    const {
        handleSubmit,
        formState: { errors, isDirty },
        setError,
        control,
    } = useForm<IForm>({
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (inputTeamNameValue !== teamName) {
            setTeamNameIsEqual(false);
        } else {
            setTeamNameIsEqual(true);
        }
    }, [inputTeamNameValue, teamName]);

    useEffect(() => {
        if (inputSlackChannelValue !== slackChannel) {
            setSlackIsEqual(false);
        } else {
            setSlackIsEqual(true);
        }
    }, [inputSlackChannelValue, slackChannel]);

    useEffect(() => {
        if (isLoading) {
            handleOpen();
        }
        callErrors?.forEach((message) =>
            setError('teamName', {
                type: 'manual',
                message: message,
            })
        );
    }, [callErrors, setError, isLoading]);

    const handleOpen = () => {
        setOpenNotification(true);
        setTimeout(() => {
            setOpenNotification(false);
        }, 3000);
    };

    useEffect(() => {
        setInputTeamNameValue(teamName);
    }, [teamName]);

    useEffect(() => {
        if (slackChannel === null) {
            setInputSlackChannelValue('');
        } else {
            setInputSlackChannelValue(slackChannel);
        }
    }, [slackChannel]);

    return (
        <div data-testid="EditTeamForm-Container">
            <div style={{ marginRight: '10rem' }} data-testid="notificationBar-Container">
                <Notification
                    notificationAppBar
                    size={Size.small}
                    isOpened={openNotification}
                    text={
                        !!callErrors
                            ? `There was a problem changing the name of your team. Check that the name is correct and try again.`
                            : `Your team has been successfully updated!`
                    }
                    leftIconProps={{
                        icon: !!callErrors ? 'faExclamationTriangle' : 'faCheckDouble',
                        pro: true,
                    }}
                    notificationBg={!!callErrors ? Colors.error : Colors.success}
                />
            </div>
            <form
                onSubmit={handleSubmit((data) => {
                    onSubmit(data);
                })}
                accept-charset="utf-8"
                data-testid="form-container"
            >
                <StyledContainer data-testid="form-StyledContainer1">
                    <Column
                        style={{ display: 'flex', alignItems: 'center', padding: '2rem' }}
                        data-testid="form-mainColumn"
                    >
                        <IconAndText data-testid="iconAndText-container1">
                            <Column data-testid="icon-column1">
                                <Icon
                                    pro
                                    icon={'faUsersCrown'}
                                    color={Colors.primary}
                                    size={FontAwesomeSize.twoX}
                                    margin="0 0 0 2rem"
                                />
                            </Column>
                            <Column
                                style={{ flexGrow: 2, margin: '0', marginLeft: '5rem' }}
                                data-testid="title-column1"
                            >
                                <h5 style={{ color: 'rgb(24, 107, 237)', margin: '0' }}>
                                    Edit Team
                                </h5>
                            </Column>
                        </IconAndText>

                        <Column data-testid="buttons-column">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div>
                                    <Button
                                        margin={'0 2rem 0 0'}
                                        buttonColor={Colors.grey}
                                        onClick={() => {
                                            history.push({
                                                pathname: `/workspaces/${workspaceSlug}/settings/teams`,
                                            });
                                        }}
                                        buttonText="Back To Teams"
                                        type="button"
                                        size={Size.small}
                                    />
                                </div>

                                <div>
                                    <Button
                                        margin={'0'}
                                        buttonColor={Colors.success}
                                        onClick={() => {
                                            // intended empty
                                        }}
                                        type="submit"
                                        disabled={
                                            !!errors.teamName ||
                                            isLoading ||
                                            !isDirty ||
                                            (teamNameIsEqual && slackIsEqual)
                                        }
                                        buttonText="Save Changes"
                                        size={Size.small}
                                    />
                                </div>
                            </div>
                        </Column>
                    </Column>
                </StyledContainer>

                <StyledContainer>
                    <Column
                        style={{ display: 'flex', alignItems: 'center', padding: '2rem' }}
                        data-testid="form-StyledContainer2"
                    >
                        <IconAndText data-testid="iconAndText-container2">
                            <Column data-testid="icon-column2">
                                <Icon
                                    pro
                                    icon={'faClipboardList'}
                                    color={Colors.black}
                                    size={FontAwesomeSize.twoX}
                                    margin="0 0 0 2rem"
                                />
                            </Column>
                            <ColumnText
                                style={{ flexGrow: 2, margin: '0', marginLeft: '5rem' }}
                                data-testid="title-column2"
                            >
                                <h5 style={{ color: 'rgb(34, 53, 84)' }}>General</h5>
                                <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                                    Edit the basic information for your team.
                                </p>
                            </ColumnText>
                        </IconAndText>
                    </Column>
                    <ColumnInput>
                        <Grid container direction="column" wrap="wrap">
                            <div style={{ margin: '2rem 2rem 0 0' }} data-testid="input-container">
                                <Controller
                                    render={({ field: { onChange } }) => (
                                        <Input
                                            onChange={(e) => {
                                                const targetValue = e.target.value;
                                                setInputTeamNameValue(targetValue);
                                                onChange(targetValue);
                                            }}
                                            placeholder={teamName}
                                            value={inputTeamNameValue}
                                            error={!!errors.teamName}
                                            errorText={errors?.teamName?.message || ''}
                                            label={'Team Name'}
                                        />
                                    )}
                                    name="teamName"
                                    rules={{
                                        required: 'Team name is required',
                                        pattern: {
                                            value: /^(?=.*[^\W_])[\w' ]*$/,
                                            message:
                                                'Ooops...No special characters. Try using letters and/or numbers instead.',
                                        },
                                    }}
                                    defaultValue={inputTeamNameValue}
                                    control={control}
                                />
                            </div>
                        </Grid>
                    </ColumnInput>
                </StyledContainer>
                <StyledContainer>
                    <Column style={{ display: 'flex', alignItems: 'center', padding: '2rem' }}>
                        <IconAndText>
                            <Column>
                                <Icon
                                    icon={'faSlack'}
                                    color={Colors.black}
                                    size={FontAwesomeSize.twoX}
                                    margin="0 0 0 2rem"
                                    brand={true}
                                />
                            </Column>
                            <ColumnText style={{ flexGrow: 2, margin: '0', marginLeft: '5rem' }}>
                                <h5 style={{ color: 'rgb(34, 53, 84)' }}>Slack Configuration</h5>
                                <p className="body-medium" style={{ color: 'rgb(34, 53, 84)' }}>
                                    Edit the PartnerHero Slack channel name where you'd like Beacon
                                    to send regular team updates.
                                </p>
                            </ColumnText>
                        </IconAndText>
                    </Column>
                    <ColumnInput>
                        <Grid container direction="column" wrap="wrap">
                            <div style={{ margin: '3rem 2rem auto 0' }}>
                                <Controller
                                    render={({ field: { onChange } }) => (
                                        <Input
                                            onChange={(e) => {
                                                const targetValue = e.target.value;
                                                setInputSlackChannelValue(targetValue);
                                                onChange(targetValue);
                                            }}
                                            placeholder={slackChannel ? '' : 'boca-juniors'}
                                            value={inputSlackChannelValue}
                                            error={!!errors.slackChannel}
                                            errorText={errors?.slackChannel?.message || ''}
                                            label={'Slack Channel Name'}
                                        />
                                    )}
                                    name="slackChannel"
                                    rules={{
                                        pattern: {
                                            value: /^[a-z0-9~ -]*$/,
                                            message:
                                                'Ooops...you can’t use upper case for slack channels name. Try using lowercase letters instead.',
                                        },
                                    }}
                                    defaultValue={inputSlackChannelValue}
                                    control={control}
                                />
                            </div>
                        </Grid>
                    </ColumnInput>
                </StyledContainer>
            </form>
        </div>
    );
};

export default EditTeamForm;
