import { FC } from 'react';
import styled from 'styled-components';
import { Grid } from '@partnerhero/new_design_system.layout.grid';
import { Icon } from '@partnerhero/new_design_system.icons.icon';
import { Button } from '@partnerhero/new_design_system.buttons.button';
import { FontAwesomeSize, Colors } from '@partnerhero/new_design_system.utils.enums';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const BlockContainer = styled.div`
    background: white;
    border-radius: 3px;
    @media (min-width: 768px) {
        width: 904px;
        height: 434px;
    }
`;

const CenterBlockContainer = styled.div`
    background: white;
    @media (min-width: 768px) {
        width: 632.5px;
        height: 231px;
    }
`;

const FactTitle = styled.small`
    color: rgb(34, 53, 84);
`;

const Fact = styled.small`
    color: rgb(144, 154, 169);
    text-align: center;
`;

const NotFoundDialog: FC = () => {
    const history = useHistory();

    return (
        <StyledContainer data-testid="NotFoundDialog_test">
            <BlockContainer data-testid="NotFoundDialog_blockContainer">
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <div style={{ marginTop: '50px' }}>
                            <Icon
                                pro
                                icon="faCctv"
                                color={Colors.primary}
                                size={FontAwesomeSize.threeX}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <CenterBlockContainer data-testid="NotFoundDialog_centerBlockContainer">
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <h4
                                        style={{
                                            color: 'rgb(34, 53, 84)',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Ah…We’ve spotted a problem
                                    </h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <p
                                        className="body-medium"
                                        style={{
                                            color: 'rgb(34, 53, 84)',
                                            textAlign: 'center',
                                            marginBottom: '0rem',
                                        }}
                                    >
                                        Looks like you have ended up somewhere that we didn’t expect
                                        you to be. Don’t worry there is no trouble here, just head
                                        back and we’ll see what went wrong on our end.
                                    </p>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        buttonColor={Colors.success}
                                        onClick={() => history.push('/')}
                                        type="submit"
                                        buttonText="Return to Dashboard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                <FactTitle className="bold-small">
                                                    Did you know?
                                                </FactTitle>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Fact className="body-small">
                                                    The word Electric, comes from the Greek word for
                                                    Amber. Greeks discovered that by rubbing Amber
                                                    on cats fur it would create a static charge.
                                                </Fact>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </CenterBlockContainer>
                    </Grid>
                </Grid>
            </BlockContainer>
        </StyledContainer>
    );
};

export default NotFoundDialog;
