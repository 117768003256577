import { gql } from '@apollo/client';

export const WorkspaceMember = gql`
    fragment WorkspaceMember on WorkspaceMember {
        id
        workspaceId
        userId
        isOwner
        name
        email
    }
`;

export const GET_ALL_WORKSPACE_MEMBERS = gql`
    query GetAllWorkspaceMembers($workspaceId: String!) {
        getAllWorkspaceMembers(workspaceId: $workspaceId) {
            ...WorkspaceMember
        }
    }
    ${WorkspaceMember}
`;
